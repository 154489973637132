import { gsap, Power3 } from 'gsap'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as LogoPromoGr } from '../../assets/images/logo-promo-gr.svg'
import { ReactComponent as LogoPromo } from '../../assets/images/logo-promo.svg'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import { colors } from '../../assets/Styles'
import { setLoader } from '../../actions/pageActionCreators'

const Container = styled.div.attrs({
  className: 'loader',
})`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 300;

  .logo-container {
    .logo {
      visibility: hidden;
      opacity: 0;
      height: 8rem;
      width: 8rem;
    }

    .logo-promo {
      visibility: hidden;
      opacity: 0;
      height: 8rem;
      width: 8rem;
    }
  }
`

const Background = styled.div`
  visibility: hidden;
  opacity: 0;
  background: ${colors.white};
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  transform: scale(1);
  z-index: -1;
  left: 50%;
  margin-left: -20px;
  top: 40%;
  margin-top: -20px;
`

const Loader = (props) => {
  const logo = useRef()
  const container = useRef()
  const background = useRef()
  const logoPromo = useRef()

  const { locale, withPromo, setLoader } = props
  let tl = gsap.timeline({ defaults: { ease: Power3.easeInOut } })
  useEffect(() => {
    tl.to(
      container.current,
      {
        duration: 0.5,
        autoAlpha: 1,
      },
      '-=3',
    )
      .to(
        logo.current,

        {
          duration: 0.5,
          autoAlpha: 1,
        },
      )
      .to(
        logoPromo.current,
        {
          duration: 0.5,
          autoAlpha: 1,
        },
        '+=0.5',
      )

      .to(
        logo.current,
        {
          duration: 0.2,
          autoAlpha: 0,
        },
        '+=0.5',
      )
      .to(
        logoPromo.current,

        {
          duration: 0.2,
          autoAlpha: 0,
        },
        '-=0.3',
      )
      .add(() => setLoader(false))
      .to(container.current, {
        duration: 0.1,
        autoAlpha: 0,
      })

    return () => {}
  }, [locale, tl])

  return (
    <Container ref={container}>
      <Background ref={background} />
      <div className="logo-container">
        <Logo height="8rem" className="logo" ref={logo} />

        <div className="logo-promo" ref={logoPromo}>
          {withPromo && (
            <React.Fragment>
              {locale === 'en' ? <LogoPromo /> : <LogoPromoGr />}
            </React.Fragment>
          )}
        </div>
      </div>
    </Container>
  )
}

Loader.propTypes = {
  locale: PropTypes.string.isRequired,
  withPromo: PropTypes.bool.isRequired,
}

export default connect(null, {
  setLoader,
})(Loader)
