import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'

const Styling = styled.div`
  background: white;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

export default function Background() {
  const backgroundRef = useRef()
  useEffect(() => {
    const timeline = gsap.timeline()
    timeline.to(backgroundRef.current, {
      duration: 0.3,
      ease: 'power3.easeInOut',
      autoAlpha: 0.9,
    })
  })

  return <Styling ref={backgroundRef} />
}
