import { gsap } from 'gsap'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { colors, media } from '../../assets/Styles'
import { LocalizedNavLink } from '../../modules'

const Li = styled.li`
  display: flex;
  margin: 0.5rem 0;

  ${media.phone` 
    justify-content: center;
    margin: 1rem 0;
  `}
  a {
    font-family: 'Superclarendon';
    transition: all 0.2s ease-in-out;
    width: auto;
    font-size: 200%;
    line-height: 3.2rem;
    color: ${colors.dark1};
    box-shadow: none;
    border-bottom: 0.0625rem solid transparent;

    ${media.phone` 
    `}
    caption {
      font-size: 50%;
      line-height: 0.5rem;
      display: inherit;
      margin: 0 auto;
    }

    &:hover {
      animation: border-bottom 0.5s ease-in-out;
      color: ${colors.dark4};
      /* border-bottom: 0.0625rem solid ${colors.dark5}; */
      /* text-shadow: -2px -2px white, -2px 2px white, 2px -2px white,
        2px 2px white; */
    }

    ${media.phone` 
      padding-bottom: 2px;
      line-height: 2rem;
      text-align: center;
      font-size: 140%;
      ${(a) =>
        a.clicked &&
        css`
          /* box-shadow: 0px 0.03em 0px 0px ${colors.dark5}; */
        `}
    `}
  }

  a.defaultProps = {
    activeclassname: 'active';
  }
`

function NavItem(props) {
  const liRef = React.useRef()
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    const { target } = props
    const timeline = gsap.timeline()
    timeline
      .set(liRef.current, {
        autoAlpha: 0,
      })

      .to(
        liRef.current,

        {
          duration: 0.7,
          autoAlpha: 1,
          delay: 0.1 * target,
          ease: 'power3.easeInOut',
        },
        '+=0.5',
      )
  }, [])

  const handleClick = () => {
    setClicked(true)
  }

  const { route, className } = props
  return (
    <Li key={route.path} ref={liRef} clicked={clicked}>
      <LocalizedNavLink
        isinactive={route.isInactive}
        to={route.path}
        onClick={handleClick}
        className={className}
      >
        <FormattedMessage id={`${route.name}`}>
          {(message) => message}
        </FormattedMessage>
        {route.caption && (
          <caption>
            <FormattedMessage id={`${route.caption}`}>
              {(message) => message}
            </FormattedMessage>
          </caption>
        )}
      </LocalizedNavLink>
    </Li>
  )
}

NavItem.propTypes = {
  route: PropTypes.object.isRequired,
  target: PropTypes.number.isRequired,
}

export default NavItem
