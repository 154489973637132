import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs-plugin-utc'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(utc)

const DatePickerWithFormik = ({ form: { setFieldValue, values } }) => {
  const [date, setDate] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const handleDateChange = (selectedDate) => {
    const utcDate = selectedDate.utc().format() // Convert to UTC format
    console.log('date', utcDate)
    setFieldValue('date', utcDate)
    setDate(utcDate)
    setIsOpen(false)
  }

  const intl = useIntl()

  return (
    <DatePicker
      value={values.date}
      onChange={handleDateChange}
      onClickOutside={() => setIsOpen(false)} // Close on outside click
      placeholder={intl.messages['reserve.date.placeholder']}
      minDate={new Date()} // Disables all past dates
    />
  )
}

export default DatePickerWithFormik
