export const CHANGE_VIEWPORT = 'CHANGE_VIEWPORT'
export const RESET_VIEWPORT = 'RESET_VIEWPORT'
export const REQUEST_PROJECTS = 'REQUEST_PROJECTS'
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS'
export const SET_HOVERED = 'SET_HOVERED'
export const SET_SELECTED = 'SET_SELECTED'

export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE'

// Contact
export const REQUEST_SUBMIT = 'REQUEST_SUBMIT'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE'
export const RESET_FORM = 'RESET_FORM'

// Wizard
export const CHANGE_PAGE = 'CHANGE_PAGE'

export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_LOADER = 'SET_LOADER'
