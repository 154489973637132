import { gsap } from 'gsap'

export default {
  enterTimeline(node) {
    const timeline = gsap.timeline({ paused: true })
    return timeline.to(
      node,
      {
        duration: 1.5,
        left: 0,
        ease: 'power2.easeInOut',
      },
      '+0.5',
    )
  },
  exitTimeline(node) {
    const timeline = gsap.timeline({ paused: true })

    return timeline.to(node, {
      duration: 0.7,
      x: '-100%',
      ease: 'power2.easeInOut',
    })
  },
}
