import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { setHovered, setSelected } from '../../../actions/projectActionCreators'
// import Close from '../../../assets/Close'
import { colors, device } from '../../../assets/Styles'
import { getProjects } from '../../../reducers'
import Project from '../Project/Project'

const Styling = styled.div.attrs({
  className: 'sidebar-wrapper',
})`
  overflow: overlay;
  position: absolute;
  width: 490px;
  background-color: white;

  border-right: 1px solid;
  z-index: 2;
  left: -490px;
  bottom: 0;
  top: 0;
  @media ${device.tablet} {
    width: 100%;
    left: -100%;
  }
  .list-container {
    position: absolute;
    left: 0;
    width: 100%;
    @media ${device.tablet} {
      position: relative;
    }
    header {
      display: flex;
      flex-direction: column;

      margin: 1rem 1rem 2rem 1rem;
      a {
        flex: 0 1 100%;
      }
      nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        justify-content: flex-end;
      }
      h1 {
        color: ${colors.dark2};
        margin: 0;
        width: 100%;
        font-size: 220%;
      }
    }
    .projects-container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      flex-direction: row;
      min-height: 100vh;
      padding: 0 1rem 4rem 1rem;
    }
  }
`

/* const Span = styled.span`
  position: relative;
  width: 25px;
  height: 25px;
` */

const List = props => {
  const {
    history,
    projects,
    setSelected,
    setHovered,
    selectedId,
    hoveredId,
  } = props
  return (
    <Styling>
      <div className="list-container">
        <header>
          <nav>
            {/* <LocalizedNavLink to="lefkas">
              <FormattedMessage id="lefkas.sidebar.start">
                {message => message}
              </FormattedMessage>
            </LocalizedNavLink> */}

            {/* <Span>
              <LocalizedNavLink to="lefkas/map">
                <Close color="black" />
              </LocalizedNavLink>
            </Span> */}
          </nav>
          <h1>
            <FormattedMessage id="lefkas.sidebar.projects">
              {message => message}
            </FormattedMessage>
          </h1>
        </header>
        <div className="projects-container">
          {projects.length !== 0 &&
            projects.map((project, index) => {
              return (
                <Project
                  active={
                    project.slug === hoveredId || project.slug === selectedId
                  }
                  history={history}
                  setSelected={setSelected}
                  setHovered={setHovered}
                  selectedId={selectedId}
                  hoveredId={hoveredId}
                  index={index}
                  key={project.slug}
                  project={project}
                />
              )
            })}
        </div>
      </div>
    </Styling>
  )
}

const mapStateToProps = state => ({
  projects: getProjects(state),
  hoveredId: state.projects.hoveredId,
  selectedId: state.projects.selectedId,
})

export default withRouter(
  connect(mapStateToProps, { setSelected, setHovered })(List),
)
