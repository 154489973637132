import { createGlobalStyle } from 'styled-components'
import { colors, device } from '../assets/Styles'

export const GlobalStyle = createGlobalStyle`

  * { box-sizing: border-box; }
  body {
    font-family: 'Murecho', sans-serif;
    color: ${colors.dark0};
    letter-spacing: 0.2px;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    -webkit-font-smoothing: antialiased;
	   -moz-osx-font-smoothing: grayscale;
     text-rendering: optimizeLegibility;
      @media ${device.tablet} {
      background: white;
      }
  }
  .app-container {
  visibility: hidden;
  overflow-x: hidden;
  background-color: white;
  position: relative;
  width: 100%;
  max-width: 1440px;
  height: 100vh;
  margin: 0 auto;
  box-shadow: 8px 0 8px -8px rgba(0, 0, 0, 0.3),
    -8px 0 8px -8px rgba(0, 0, 0, 0.3);
  @media ${device.tablet} {
    box-shadow: none;
    /* height: 100%; */
  }

  }
  button {
    height: 36px;
    transition: all 0.3s ease-out;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.5px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    user-select: none;
    vertical-align: middle;

  }
  a {
    font-family: 'Superclarendon';
    cursor: pointer;
    text-decoration: none;
    transition: color 0.1s ease-in-out;
    color: ${colors.dark2};
    font-size: 95%;
    &:hover,
    &:active,
    &:focus {
      color: ${colors.dark2};
      outline: none;
    }
    
  }
  p {
    letter-spacing: 0.2px;
    word-spacing: 2px;
    margin: 0;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Superclarendon';
    font-weight: 300;
  }
  h1 {
    border-bottom: 0.02em solid ${colors.light5};
    word-spacing: -0.2rem;
    line-height: 1.25em;
    font-size: 3.4rem;
    display: inline-block;
  }

  h2 {
    font-size: 250%;
  }

  h3 {
    font-size: 170%;
  }

  h4 {
    font-size: 140%;
  }
  h5 {
    font-size: 140%;
  }
`
