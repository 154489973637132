import * as prismic from '@prismicio/client'

// Fill in your repository name
const endpoint = prismic.getEndpoint('pardalo')
export const client = prismic.createClient(endpoint, {
  // If your repository is private, add an access token
  accessToken:
    'MC5aQ0ttTlJBQUFDUUE1QnhY.77-977-977-9GyoG77-9A--_vTbvv73vv73vv71V77-9OnHvv73vv73vv70FRO-_ve-_ve-_vX5gCFId77-977-9',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  // routes: [
  //   {
  //     type: 'homepage',
  //     path: '/',
  //   },
  // ],
})
