import * as emailjs from 'emailjs-com'

// Action types
import {
  REQUEST_SUBMIT,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  RESET_FORM,
} from '../constants'

export const resetForm = () => {
  return {
    type: RESET_FORM,
  }
}

const requestSubmit = () => {
  return {
    type: REQUEST_SUBMIT,
  }
}

const sendMessageSuccess = () => {
  return {
    type: SEND_MESSAGE_SUCCESS,
  }
}

const sendMessageFailure = (error) => {
  return {
    type: SEND_MESSAGE_FAILURE,
    error,
  }
}

export const sendMessage = (values) => {
  const {
    REACT_APP_EMAILJS_USER_ID: userId,
    REACT_APP_SERVICE_ID: serviceId,
    REACT_APP_TEMPLATE_ID: templateId,
  } = process.env
  return async (dispatch) => {
    try {
      dispatch(requestSubmit())
      await emailjs.send(serviceId, templateId, values, userId)
      dispatch(sendMessageSuccess())
    } catch (e) {
      dispatch(sendMessageFailure(e))
    }
  }
}
