import { Power3, TimelineLite } from 'gsap'
import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import Logo from '../assets/Logo'
import { colors } from '../assets/Styles'
import Social from './shared/Social'

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  min-height: 100vh;
`

const LogoContainer = styled.div`
  align-self: center;
  margin-top: 2rem;
`

const Footer = styled.footer`
  bottom: 0;
`

const MessagesContainer = styled.article`
  display: flex;
  flex-flow: column;

  font-size: 2.5em;
  font-weight: 700;
  margin: 2rem 2rem 4rem 2rem;
  color: ${colors.grey};
`

const InfoMessage = styled.div`
  text-align: center;
  margin: 2rem 0 0 1rem;
  font-weight: 300;
  font-size: 65%;
`

const NotFound = () => {
  const intl = useIntl()
  const infoRef = useRef()

  useEffect(() => {
    const timeline = new TimelineLite()
    timeline.to(
      infoRef.current,
      1,
      {
        ease: Power3.easeOut,
        autoAlpha: 1,
      },
      '+=0.7',
    )
  })

  return (
    <NotFoundContainer>
      <Helmet>
        <title>{intl.messages['meta.pageNotFound']}</title>
      </Helmet>
      <LogoContainer>
        <Logo height="6rem" />
      </LogoContainer>
      <MessagesContainer>
        <div ref={infoRef}>
          <InfoMessage>
            <FormattedMessage id="notFound.info" />
          </InfoMessage>
        </div>
      </MessagesContainer>
      <Footer>
        <Social />
      </Footer>
    </NotFoundContainer>
  )
}

export default NotFound
