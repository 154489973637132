import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { media, sizes } from '../../assets/Styles'
import { LocalizedNavLink } from '../../modules'
import Logo from './Logo'

const Container = styled.header`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin: 2rem;
  z-index: 200;
  ${media.phone`
    margin: 0.5rem;
  `};
`

const Header = (props) => {
  const { locale, windowSize } = props
  return (
    // <Fade {...props}>
    <Container>
      <LocalizedNavLink to="">
        {windowSize.width > sizes.phone ? (
          <Logo
            locale={locale}
            logoHeight="4rem"
            promoHeight="1.5rem"
            withPromo
          />
        ) : (
          <Logo
            locale={locale}
            withPromo
            logoHeight="4rem"
            promoHeight="1.5rem"
          />
        )}
      </LocalizedNavLink>
    </Container>
    // </Fade>
  )
}

Header.propTypes = {
  locale: PropTypes.string.isRequired,
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  locale: state.page.locale,
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps, {})(Header)
