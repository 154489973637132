import React from 'react'
import { FormattedMessage } from 'react-intl'
import SEO from '../SEO'
import { BlurImageLoader } from '../shared/BlurImageLoader'
import { Content, H1, ImageColumn, TextColumn, Wrapper } from './HomeStyles'

const Embroideries = React.memo(() => {
  return (
    <Wrapper>
      <SEO
        title="home.embroideries.title"
        description="home.embroideries.meta.description"
      />
      <TextColumn alignItems="flex-end">
        <Content>
          <H1>
            <FormattedMessage id="home.embroideries.title">
              {(message) => message}
            </FormattedMessage>
          </H1>
          <p>
            <FormattedMessage id="home.embroideries.p">
              {(message) => message}
            </FormattedMessage>
          </p>
        </Content>
      </TextColumn>
      <ImageColumn alignItems="flex-start" maxWidth="650px">
        <BlurImageLoader name="embroderies_ah0gwi" />
      </ImageColumn>
    </Wrapper>
  )
})

export default Embroideries
