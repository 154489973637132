import React from 'react'
import styled from 'styled-components'
import { colors, media } from '../../../../assets/Styles'
import { LocalizedNavLink } from '../../../../modules'

const NavItem = styled(LocalizedNavLink)`
  font-family: 'Superclarendon';
  line-height: 1.5rem;
  margin: 0 1rem;
  font-size: 140%;
  max-width: 16rem;
  letter-spacing: 0.5px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
  color: ${colors.dark1};
  border-bottom: 2px solid transparent;
  &:hover,
  &:focus {
    color: ${colors.dark5};
  }
  &.${props => props.activeClassName} {
    animation: border-bottom 0.5s ease-in-out;
    color: ${colors.dark4};
    border-bottom: 0.01em solid ${colors.dark5};
    /* text-shadow: -2px -2px white, -2px 2px white, 2px -2px white, 2px 2px white; */
  }

  ${media.phone`
    font-size: 90%;
    padding: 0;
    margin-right: 10px;`};
`

NavItem.defaultProps = {
  activeClassName: 'active',
}
const MenuNavItem = props => {
  return <NavItem {...props} />
}

export default MenuNavItem
