import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { LocalizedRoute } from '../../../modules'
import FirstPage from './FirstPage'
import SecondPage from './SecondPage'
import ThirdPage from './ThirdPage'

const routes = [
  { path: 'restaurant/menu/appetizers', Component: FirstPage },
  { path: 'restaurant/menu/salads', Component: SecondPage },
  { path: 'restaurant/menu/main', Component: ThirdPage },
  // { path: 'restaurant/menu/grilled', Component: FourthPage },
  // { path: 'restaurant/menu/daily', Component: FifthPage },
]

const Container = styled.div`
  position: relative;
`

const MenuRoutes = () => {
  const menuPath = useRouteMatch({
    path: '/:locale/restaurant/menu',
    isExact: true,
    strict: false,
  })
  const history = useHistory()
  const intl = useIntl()
  useEffect(() => {
    if (menuPath.isExact)
      history.push(`/${intl.locale}/restaurant/menu/appetizers`)
  }, [])
  return (
    <Container>
      {routes.map(({ path, Component }) => (
        <LocalizedRoute key={path} exact path={path}>
          {({ match }) => <Component delay={0.5} in={match != null} />}
        </LocalizedRoute>
      ))}
    </Container>
  )
}

export default MenuRoutes
