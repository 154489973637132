import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { colors, media } from '../../../assets/Styles'
import { LocalizedNavLink } from '../../../modules'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  ${media.phone`
    max-width: 75%;
    margin: 0 auto;
  `};
`

const BlackButton = styled.a`
  border: 1px solid ${colors.dark1};
  border-radius: 2px;
  line-height: 36px;
  padding: 0 16px;
  text-align: center;
  width: 100%;
  margin: 0 0 1rem 0;
  &:hover,
  &:focus {
    background: white;
    color: ${colors.dark3};
    border-color: ${colors.dark3};
  }
`

const BorderButton = styled.button`
  font-family: 'Superclarendon';
  width: 100%;
  padding: 0.5em;
  color: ${colors.dark1};
  background: white;
  line-height: 1.3em;
  margin-top: 1rem;
  border: 1px solid ${colors.dark1};
  &:hover,
  &:focus {
    background: white;
    color: ${colors.dark3};
    border-color: ${colors.dark3};
  }
  ${media.phone`
    width: 100%;
    
    `};
`

const Actions = React.memo(() => {
  return (
    <Container>
      <BorderButton
        onClick={() =>
          window.open(
            'https://goo.gl/maps/ZyHw6CdEsDF2',
            '_blank',
            'noopener noreferrer',
          )
        }
      >
        <FormattedMessage id="address.directions">
          {(message) => message}
        </FormattedMessage>
      </BorderButton>
      <LocalizedNavLink to="reserve">
        <BorderButton>
          <FormattedMessage id="navigation.reserve">
            {(message) => message}
          </FormattedMessage>
        </BorderButton>
      </LocalizedNavLink>
    </Container>
  )
})

Actions.propTypes = {}

export default Actions
