import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Close from '../../../assets/Close'
import { device } from '../../../assets/Styles'
import { LocalizedNavLink } from '../../../modules'
import { getSelectedProject } from '../../../reducers'
import Body from './Body'

const Styling = styled.div.attrs({
  className: 'sidebar-wrapper',
})`
  overflow: overlay;
  position: absolute;
  width: 490px;
  background-color: white;

  border-right: 1px solid;
  /* Should be on top of Button */
  z-index: 101;
  left: -490px;
  bottom: 0;
  top: 0;
  @media ${device.tablet} {
    width: 100%;
    left: -100%;
  }
  .preview-container {
    padding: 1.5rem;
    position: absolute;
    left: 0;
    header {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-end;
    }
  }
`

const Preview = props => {
  const { project, isFetching } = props
  return (
    <Styling>
      <div className="preview-container">
        <header>
          <LocalizedNavLink to="lefkas/map/projects">
            <Close color="black" />
          </LocalizedNavLink>
        </header>
        {isFetching && <div> Loading... </div>}
        {project && <Body project={project} />}

        <footer />
      </div>
    </Styling>
  )
}

Preview.defaultProps = {
  project: null,
}

Preview.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  project: PropTypes.objectOf({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
}

const mapStateToProps = state => ({
  project: getSelectedProject(state),
  isFetching: state.projects.isFetching,
})

export default connect(mapStateToProps, {})(Preview)
