import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { sendMessage } from '../../../actions/contactActionCreators'
import {
  Button,
  Error,
  ErrorSpan,
  FieldWrapper,
  Form,
  Input,
  InputWrapper,
  Item,
  Label,
  Row,
  Span,
  TextArea,
} from './ContactStyles'

const validate = (values, props) => {
  const { intl } = props
  const errors = {}
  if (!values.name) {
    errors.name = intl.messages['contactForm.validation.required']
  } else if (values.name.length < 3) {
    errors.name = intl.messages['contactForm.validation.name']
  }
  if (!values.email) {
    errors.email = intl.messages['contactForm.validation.required']
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = intl.messages['contactForm.validation.email']
  }
  // if (!values.phone) {
  //   errors.phone = intl.messages['contactForm.validation.required']
  // }
  // if (values.phone) {
  //   if (isNaN(Number(values.phone))) {
  //     errors.phone = intl.messages['contactForm.validation.number']
  //   } else if (Number(values.phone) > 1 && Number(values.phone) < 7) {
  //     errors.phone = intl.messages['contactForm.validation.numberOfDigits']
  //   }
  // }

  if (!values.message) {
    errors.message = intl.messages['contactForm.validation.required']
  }
  return errors
}
// https://blog.logrocket.com/detecting-location-react-phone-number-input/

const renderField = ({
  placeholder,
  input,
  label,
  type,
  required,
  meta: { touched, error, warning },
}) => {
  return (
    <FieldWrapper>
      <Label>
        {label} {required ? <Span>*</Span> : null}
      </Label>
      <InputWrapper>
        {type === 'textarea' ? (
          <React.Fragment>
            <TextArea
              {...input}
              placeholder={placeholder}
              type={type}
              rows={6}
              cols={50}
            />
            <Error>
              {touched &&
                ((error && <ErrorSpan>{error}</ErrorSpan>) ||
                  (warning && <ErrorSpan>{warning}</ErrorSpan>))}
            </Error>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Input {...input} placeholder={placeholder} type={type} />
            <Error>
              {touched &&
                ((error && <ErrorSpan>{error}</ErrorSpan>) ||
                  (warning && <ErrorSpan>{warning}</ErrorSpan>))}
            </Error>
          </React.Fragment>
        )}
      </InputWrapper>
    </FieldWrapper>
  )
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
  }

  submitForm(values) {
    const { sendMessage, reset } = this.props
    return sendMessage(values).then(() => {
      reset()
    })
  }

  render() {
    const { handleSubmit, submitting, intl } = this.props

    return (
      <Form onSubmit={handleSubmit(this.submitForm)}>
        <Item>
          <Field
            name="name"
            type="text"
            placeholder={intl.messages['contactForm.name.placeholder']}
            component={renderField}
            required
            label={
              <FormattedMessage id="contactForm.name.label">
                {(message) => message}
              </FormattedMessage>
            }
          />
        </Item>
        <Row>
          <Item>
            <Field
              name="email"
              type="email"
              required
              component={renderField}
              placeholder={intl.messages['contactForm.email.placeholder']}
              label={intl.messages['contactForm.email.label']}
            />
          </Item>
          <Item>
            <Field
              name="phone"
              type="number"
              placeholder={intl.messages['contactForm.phoneNumber.placeholder']}
              component={renderField}
              label={intl.messages['contactForm.phoneNumber.label']}
            />
          </Item>
        </Row>
        <Item>
          <Field
            name="message"
            type="textarea"
            required
            placeholder={intl.messages['contactForm.message.placeholder']}
            component={renderField}
            label={intl.messages['contactForm.message.label']}
          />
        </Item>
        <Button type="submit" disabled={submitting}>
          <FormattedMessage id="contactForm.submit">
            {(message) => message}
          </FormattedMessage>
        </Button>
      </Form>
    )
  }
}

ContactForm = reduxForm({
  form: 'contactForm',
  destroyOnUnmount: false,
  validate,
})(ContactForm)

const mapStateToProps = () => {}

export default injectIntl(
  connect(mapStateToProps, {
    sendMessage,
  })(ContactForm),
)
