import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as LogoIcon } from './images/logo.svg'
import { colors } from './Styles'

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Wrapper = styled.div`
  cursor: pointer;
  &:hover {
    animation: ${shake} 0.5s linear;
  }
  svg {
    height: ${props => props.height};
  }
`

const Logo = ({ height, animate }) => {
  return (
    <Wrapper animate={animate} height={height}>
      <LogoIcon fill={`${colors.dark2}`} />
    </Wrapper>
  )
}

export default Logo
