import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { LocalizedNavLink } from '../../modules'
import Nav from '../Menu/Nav'
import Logo from '../shared/Logo'
import Social from '../shared/Social'
import Welcome from './Welcome'

const Styling = styled.div`
  height: 100%;
  overflow-y: scroll;
  .menu-section {
    opacity: ${(props) => (props.welcome ? 0 : 1)};
    transition: opacity 1s ease-in-out;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    height: ${(props) => props.windowSize.height}px;
    text-align: center;
    .nav-wrapper {
      width: 100%;
      nav {
        ul {
          li {
            margin: 0.5rem 0;
          }
        }
      }
      .info {
        font-size: 70%;
        margin-top: 2rem;
        p {
        }
        .contact {
          margin-top: 1rem;
          a {
            display: inline-block;
            margin-bottom: 0.5rem;
            border-bottom: 1px solid;
          }
        }
      }
    }
  }
  width: 100%;
  .social-wrapper {
    padding: 1rem 0;
  }
`

const Mobile = (props) => {
  const [welcome, closeWelcome] = useState(true)

  const { windowSize, locale } = props

  return (
    <Styling windowSize={windowSize}>
      {/* <Welcome welcome={welcome} closeWelcome={closeWelcome} /> */}

      <section className="menu-section">
        <Logo
          display="inline"
          withPromo
          locale={locale}
          logoHeight="4rem"
          promoHeight="1.5rem"
        />
        <div className="nav-wrapper">
          <Nav />
          <div className="info">
            <p>
              <FormattedMessage id="address.storeOpen.title">
                {(message) => message}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="address.storeOpen.hours">
                {(message) => message}
              </FormattedMessage>
            </p>
            <div className="contact">
              <a href="tel:+302645041769">+302645041769</a>
              <br />
              <LocalizedNavLink to="contact">hello@pardalo.gr</LocalizedNavLink>
            </div>
          </div>
        </div>
        <div className="social-wrapper">
          <Social />
        </div>
      </section>
    </Styling>
  )
}

Mobile.propTypes = {
  locale: PropTypes.string.isRequired,
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
  locale: state.page.locale,
})

export default connect(mapStateToProps)(Mobile)
