import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
// This is the thumbnail that appears when someone shares your website
// https://smakosh.com/seo-in-react-apps

import Logo from '../assets/images/logo.png'

const SEO = React.memo(
  ({
    title,
    description,
    articleBody,
    datePublished,
    dateModified,
    cover,
    readTime,
    author,
    type = 'Restaurant',
  }) => {
    const location = useLocation()
    const intl = useIntl()
    const config = {
      url: 'https://www.pardalo.gr',
      defaultTitle: intl.messages['meta.defaultTitle'],
      defaultDescription: intl.messages['meta.defaultDescription'],
      social: {
        facebook: '143527132486673',
        twitter: '@PKatsiki',
      },
      socialLinks: {
        facebook: 'https://www.facebook.com/pardalo/',
        twitter: 'https://twitter.com/pkatsiki',
        instagram: 'instagram.com/pardalo_katsiki_lefkas/',
      },
      address: {
        street: intl.messages['meta.street'],
        region: intl.messages['meta.region'],
        country: intl.messages['meta.country'],
        postalCode: intl.messages['meta.postalCode'],
        locality: intl.messages['meta.locality'],
      },
      contact: {
        email: 'hello@pardalo.gr',
        phone: '+302645041769',
      },
    }

    // This is Structured data that is recommended to have according to Google
    // You can read more about it on Google's own documentation about structured data
    // The first string is for the Article schema and the second one for the organization schema
    const structuredDataArticle = `{
		"@context": "http://schema.org",
    "@type": "${type}",
		"mainEntityOfPage": {
			"@type": "WebPage",
			"@id": "https://google.com/article"
		},
		"headline": "${description}",
		"image": "${cover ? `${config.url}${cover}` : `${config.url}${Logo}`}",
		"datePublished": "${datePublished}",
		"dateModified": "${dateModified}",
		"author": {
			"@type": "Person",
			"name": "${author}"
		},
		"articleBody": "${articleBody}",
		"publisher": {
			"@type": "Organization",
			"name": "${author}",
			"logo": {
				"@type": "ImageObject",
				"url": "${config.url}${Logo}"
			}
		},
		"description": "${description}",
		"url": "${config.url}${location.pathname}"
  }`

    const structuredDataLocalBusiness = `{
		"@context": "http://schema.org",
    "@type": "${type}",
    "image": ["${config.url}${Logo}"
    ],
		"@id": "${config.url}",
		"name": "${config.defaultTitle}",
		"address": {
    "@type": "PostalAddress",
    "streetAddress": "${config.address.street}",
    "addressLocality": "${config.address.locality}",
    "addressRegion": "${config.address.region}",
    "postalCode": "${config.address.postalCode}",
    "addressCountry": "${config.address.country}"
  },
		"geo": {
    "@type": "GeoCoordinates",
    "latitude": 38.664,
    "longitude": 20.650328
  },
	"url": "${config.url}",
  "telephone": "${config.contact.phone}",
  "servesCuisine": "Greek",
  "priceRange": "$$",
  "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "12:00",
      "closes": "23:30"
    }
  ],
    "menu": "https://www.pardalo.gr/el/restaurant/menu",
    "acceptsReservations": "True",
		"sameAs": [
			"${config.socialLinks.twitter}",
			"${config.socialLinks.facebook}",
			"${config.socialLinks.instagram}"
		]
    }`
    return (
      // Notice I'm using react-helmet to inject these elements within the header tag
      <Helmet>
        {/* The description that appears under the title of your website appears on search engines results */}
        <meta
          name="description"
          content={intl.messages[description] || config.defaultDescription}
        />

        {/* The thumbnail of your website */}
        <meta
          name="image"
          content={cover ? `${config.url}${cover}` : `${config.url}${Logo}`}
        />

        {/* Opengraph meta tags for Facebook & LinkedIn */}
        <meta property="og:url" content={`${config.url}${location.pathname}`} />
        <meta
          property="og:type"
          content={type === 'NewsArticle' ? 'NewsArticle' : 'Restaurant'}
        />
        <meta
          property="og:title"
          content={
            title
              ? `${config.defaultTitle} | ${intl.messages[title]}`
              : config.defaultTitle
          }
        />
        <meta
          property="og:description"
          content={intl.messages[description] || config.defaultDescription}
        />
        <meta
          property="og:image"
          content={cover ? `${config.url}${cover}` : `${config.url}${Logo}`}
        />

        <meta property="fb:app_id" content={config.social.facebook} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={config.socialLinks.twitter} />
        <meta name="twitter:site" content={config.social.twitter} />
        <meta
          name="twitter:title"
          content={
            title
              ? `${config.defaultTitle} | ${intl.messages[title]}`
              : config.defaultTitle
          }
        />
        <meta
          name="twitter:description"
          content={intl.messages[description] || config.defaultDescription}
        />
        <meta
          name="twitter:image:src"
          content={cover ? `${config.url}${cover}` : `${config.url}${Logo}`}
        />
        {type === 'NewsArticle' && (
          <meta name="twitter:label1" value="Reading time" />
        )}
        {type === 'NewsArticle' && (
          <meta name="twitter:data1" value={`${readTime} min read`} />
        )}
        {type === 'NewsArticle' && (
          <meta
            name="author"
            content="Ismail Ghallou"
            data-react-helmet="true"
          />
        )}
        {type === 'NewsArticle' && (
          <meta
            name="article:published_time"
            content={datePublished}
            data-react-helmet="true"
          />
        )}

        {/* Structured data */}
        <script type="application/ld+json">
          {type === 'NewsArticle'
            ? structuredDataArticle
            : structuredDataLocalBusiness}
        </script>

        {/* The title of your current page */}
        <title>
          {title
            ? `${config.defaultTitle} | ${intl.messages[title]}`
            : config.defaultTitle}
        </title>

        {/* Default language and direction */}
        <html lang={intl.locale} dir="ltr" />
      </Helmet>
    )
  },
)
SEO.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  articleBody: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  cover: PropTypes.string,
  readTime: PropTypes.string,
  author: PropTypes.string,
}

export default SEO
