import { gsap } from 'gsap'
import React, { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { device, media } from '../../assets/Styles'

const Styling = styled.div.attrs({
  className: 'confirmation-wrapper',
})`
  z-index: 2;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  ${media.phone`
    left: 0;
    transform: none;
    `}
  .container {
    padding: 1rem;
    margin: 1rem;
    background: white;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
    a {
      text-decoration: underline;
      cursor: pointer;
      margin-top: 1rem;
    }
  }
`

export default function Confirmation({ success, resetForm, messageId }) {
  const wrapperRef = useRef()
  useEffect(() => {
    const timeline = gsap.timeline()
    timeline.to(wrapperRef.current, {
      duration: 0.2,
      ease: 'power3.easeInOut',
      autoAlpha: 1,
    })
  }, [])

  return (
    <Styling ref={wrapperRef}>
      <div className="container">
        <p>
          {success ? (
            <FormattedMessage
              id={messageId || 'restaurant.contactUs.successMessage'}
            >
              {(message) => message}
            </FormattedMessage>
          ) : (
            <FormattedMessage id="restaurant.contactUs.failureMessage">
              {(message) => message}
            </FormattedMessage>
          )}
        </p>
        <a onClick={() => resetForm()}>
          <FormattedMessage id="restaurant.contactUs.close">
            {(message) => message}
          </FormattedMessage>
        </a>
      </div>
    </Styling>
  )
}
