import React from 'react'
import styled from 'styled-components'

const ICON = `M34.864,32L62.407,4.457c0.791-0.791,0.791-2.073,0-2.864c-0.791-0.791-2.073-0.791-2.864,0L32,29.136L4.457,1.593
  c-0.791-0.791-2.073-0.791-2.864,0s-0.791,2.073,0,2.864L29.136,32L1.593,59.543c-0.791,0.791-0.791,2.073,0,2.864
     C1.989,62.802,2.506,63,3.025,63s1.036-0.198,1.432-0.593L32,34.864l27.543,27.543C59.938,62.802,60.455,63,60.975,63
        s1.036-0.198,1.432-0.593c0.791-0.791,0.791-2.073,0-2.864L34.864,32z`

const Styling = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  svg {
    fill: ${(props) => (props.color === 'black' ? '#222' : '#fff')};
    fill-opacity: 0.5;
    transition-duration: 0.2;
    transition-property: opacity;
    cursor: pointer;
    &:hover {
      fill-opacity: 1;
    }
  }
`

const Close = ({ color, close }) => {
  return (
    <Styling color={color}>
      <svg viewBox="0 0 64 64" onClick={() => close && close()}>
        <path d={ICON} />
      </svg>
    </Styling>
  )
}

export default Close
