import React from 'react'
import { Transition } from 'react-transition-group'
import animations from './animations'
import { sizes } from '../../assets/Styles'

const duration = 1000

const Slide = React.memo(({ children, windowSize, ...props }) => {
  const enterAnimation = (node, windowSize, sizes) => {
    return windowSize.width > sizes.phone
      ? animations.fromRight(node)
      : animations.fromTop(node)
  }

  const exitAnimation = (node, windowSize, sizes) => {
    return windowSize.width > sizes.phone
      ? animations.toRight(node)
      : animations.toTop(node)
  }

  return (
    <Transition
      {...props}
      appear
      timeout={duration}
      mountOnEnter
      unmountOnExit
      onEnter={node => enterAnimation(node, windowSize, sizes)}
      onExit={node => exitAnimation(node, windowSize, sizes)}
    >
      {children}
    </Transition>
  )
})

export default Slide
