import React from 'react'
import styled from 'styled-components'
import { colors, media } from '../../assets/Styles'

const Styling = styled.div`
  margin-left: ${(props) => props.marginLeft || '0'};
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 90%;
    font-style: italic;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    justify-content: center;
    list-style: none;
    ${media.phone`
        flex: 0 1 auto;
        justify-content: center;
      `};
    li {
      margin-right: 1rem;
      list-style: none;
      height: 2rem;
      :last-child {
        margin-right: 0;
      }
      span {
        line-height: 2rem;
      }

      a {
        display: inline-block;
        font-style: italic;
      }
    }
  }
`
const Social = () => {
  return (
    <Styling>
      <h1>Βρείτε μας εδώ</h1>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer canonical"
            href="https://www.facebook.com/pardalo/"
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer canonical"
            href="https://www.instagram.com/pardalo_katsiki_lefkas/"
          >
            Instagram
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer canonical"
            href="https://www.tripadvisor.com.gr/Restaurant_Review-g3452638-d4298642-Reviews-Pardalo_Katsiki-Karya_Lefkada_Ionian_Islands.html?m=19905"
          >
            Tripadvisor
          </a>
        </li>
      </ul>
      {/* <Ul>
          <li>
            <Span>+302645041769</Span>
          </li>
          <li>
            <Span>hello@pardalo.gr</Span>
          </li>
        </Ul> */}
    </Styling>
  )
}

export default Social
