import { Power3, TweenLite } from 'gsap'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { colors, device } from '../../../assets/Styles'
import { LocalizedNavLink } from '../../../modules'
import Media from './Media'

const Styling = styled.div`
  /* Remove the gap 
  https://stackoverflow.com/questions/19038799/why-is-there-an-unexplainable-gap-between-these-inline-block-div-elements/19038875*/
  font-size: 0;
  width: 100%;
  margin: 0 0 0.5rem 0;
  border: 1px solid ${colors.light5};
  transition: border 0.2s ease-in-out;
  border-radius: 2px;
  background: ${colors.white};
  @media ${device.tablet} {
    border: none;
  }
  ${props =>
    props.active &&
    css`
      background: ${colors.white};
      border: 1px solid;
      border-radius: 2px;
    `};
  &:hover {
    border: 1px solid;
    @media ${device.tablet} {
      border: none;
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media ${device.tablet} {
      flex: 0 1 100%;
    }

    .media-wrapper {
      flex: 0 1 40%;
    }
    .content-container {
      font-size: 16px;
      flex: 4;
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 0.5rem;
      height: 5rem;
      overflow: hidden;
      @media ${device.tablet} {
        overflow: unset;
        height: 100%;
      }
      h3 {
        font-size: 130%;
        margin: 0;
      }
      .content-location {
        margin: 0;
        font-size: 85%;
        color: ${colors.dark3};
        font-style: italic;
      }
      .content-description {
        margin-top: 1rem;
        font-size: 90%;
        color: ${colors.dark2};
      }
    }
  }
`

class Project extends Component {
  constructor(props) {
    super(props)
    this.thumbRef = React.createRef()
  }

  componentDidMount() {
    const { index } = this.props
    TweenLite.set(this.thumbRef.current, {
      autoAlpha: 0,
    })
    setTimeout(() => {
      TweenLite.to(this.thumbRef.current, 0.3, {
        ease: Power3.easeInOut,
        autoAlpha: 1,
        delay: 0.3 * index,
      })
    }, 100)
  }

  render() {
    const {
      setSelected,
      active,
      setHovered,
      project: { slug, image, title, location, description },
    } = this.props

    return (
      <Styling
        active={active}
        ref={this.thumbRef}
        onMouseEnter={() => setHovered(slug)}
        onMouseLeave={() => setHovered(null)}
        onClick={() => setSelected(slug)}
      >
        <LocalizedNavLink to={`lefkas/map/projects/${slug}`}>
          <div className="container">
            <div className="media-wrapper">
              <Media url={image.url} title={image.title} width="490" />
            </div>
            <div className="content-container">
              <h3>{title}</h3>
              <div className="content-location">{location}</div>
              <div className="content-description">{description}</div>
            </div>
            <footer />
          </div>
        </LocalizedNavLink>
      </Styling>
    )
  }
}

Project.propTypes = {
  index: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  active: PropTypes.func.isRequired,
  setHovered: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default Project
