import {combineReducers} from 'redux'
import {slugifyString} from '../modules/slugifyString'
import {
  FETCH_PROJECTS_FAILURE,
  RECEIVE_PROJECTS,
  REQUEST_PROJECTS,
  SET_HOVERED,
  SET_SELECTED,
} from '../constants/ActionTypes'

const projectsInitialState = {
  selectedId: null,
  hoveredId: null,
  byId: {},
  availableIds: [],
  isFetching: false,
  fetchProjectsError: null,
}

const projects = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const selectedId = (state = projectsInitialState.selectedId, action) => {
  switch (action.type) {
    case SET_SELECTED:
      return action.id
    default:
      return state
  }
}

const hoveredId = (state = projectsInitialState.hoveredId, action) => {
  switch (action.type) {
    case SET_HOVERED:
      return action.id
    default:
      return state
  }
}

const isFetching = (state = projectsInitialState.isFetching, action) => {
  switch (action.type) {
    case REQUEST_PROJECTS:
      return true
    case FETCH_PROJECTS_FAILURE:
    case RECEIVE_PROJECTS:
      return false
    default:
      return state
  }
}

const byId = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PROJECTS:
      const assets = action.data.hasOwnProperty('includes')
        ? action.data.includes.Asset
        : null
      return {
        ...state,
        ...action.data.items.reduce((object, item) => {
          let asset = null
          if (assets && item.fields.hasOwnProperty('image')) {
            const {id: imageId} = item.fields.image.sys
            asset = assets.find(asset => asset.sys.id === imageId)
          }
          if (item.fields.coordinates) {
            item.fields.coordinates.longitude = item.fields.coordinates.lon
            item.fields.coordinates.latitude = item.fields.coordinates.lat
            delete item.fields.coordinates.lon
            delete item.fields.coordinates.lat
          } else {
            item.fields.coordinates = null
          }

          object[slugifyString(item.fields.slug)] = {
            ...item.fields,
            id: item.sys.id,
            slug: slugifyString(item.fields.slug),
            image: {
              ...item.fields.image,
              url: asset ? asset.fields.file.url : null,
              title: asset ? asset.fields.title : null,
            },
          }
          return object
        }, {}),
      }
    default:
      if (action.projectId) {
        return {
          ...state,
          [action.projectId]: projects(state[action.projectId], action),
        }
      }
      return state
  }
}

const availableIds = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_PROJECTS:
      return action.data.items.map(item => slugifyString(item.fields.slug))
    default:
      return state
  }
}

export default combineReducers({
  selectedId,
  hoveredId,
  byId,
  availableIds,
  isFetching,
})

export const getSelectedProject = state => {
  return state.byId[state.selectedId] || null
}

export const getProjects = state => {
  return state.availableIds.map(id => state.byId[id])
}
