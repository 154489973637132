import styled from 'styled-components'
import { device } from '../../assets/Styles'

export const H1 = styled.h1`
  font-size: 2.4rem;
  @media ${device.tablet} {
    font-size: 180%;
  }
`
export const Content = styled.div`
  max-width: 30rem;
  @media ${device.tablet} {
    max-width: none;
    text-align: center;
  }
`
export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 50px);
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    position: relative;
    min-height: 100vh;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 0 1rem;
    width: 100%;
    margin-top: 3rem;
  }
`

export const Container = styled.div``

export const P = styled.p``

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  justify-content: center;
  @media ${device.tablet} {
    flex: 0;
  }
`
export const ImageColumn = styled(Column).attrs({
  className: 'image',
})`
  opacity: 1;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  img {
    cursor: pointer;
    height: auto;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '500px')};
    width: 100%;
    :last-of-type {
      right: 0;
    }
  }
  @media ${device.tablet} {
    justify-content: center;
    order: 1;
    flex: 1;
  }
`

export const TextColumn = styled(Column)`
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  @media ${device.tablet} {
    align-items: center;
    padding-right: 0;
    order: 2;
    flex: 1;
    justify-content: unset;
  }
`
