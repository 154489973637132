import request from 'axios'

const API_BASE_URL = 'https://cdn.contentful.com'
const API_SPACE_ID = 'h54l4r2q6vw4'
const API_ACCESS_TOKEN =
  '02a54f7033329f8666a96d0df9a47800f09e048369a641c1d6efd7cecd1c622d'

export default {
  fetchProjects(locale) {
    const url = `${API_BASE_URL}/spaces/${API_SPACE_ID}/entries`
    const params = {
      access_token: API_ACCESS_TOKEN,
      content_type: 'project',
      locale,
    }
    return request({
      method: 'GET',
      url,
      params,
      validateStatus(status) {
        return status >= 200 && status <= 304
      },
    })
  },
}
