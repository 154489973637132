export default {
  en: {
    meta: {
      defaultTitle: 'Pardalo Katsiki restaurant Lefkas',
      defaultDescription:
        'Enjoy tranditional recipies from Lefkas under the shade of old plain trees.',
      defaultKeywords:
        'restaurant, restaurant, funky, goat, lefkas, pardalo, katsiki, food, traditional',
      street: 'Karya village, Lefkas',
      locality: 'Lefkas',
      region: 'Ionian Islands',
      postalCode: '31080',
      country: 'Greece',
    },
    reserve: {
      successMessage:
        'We have received your reservation request. Please check your email for a confirmation message from us shortly. Please do not hesitate to contact our restaurant directly at +302645041769.',
      buttons: {
        next: 'Next',
        previous: 'Previous',
        submit: 'Submit reservation',
        submitting: 'Submitting...',
      },
      steps: {
        one: {
          title: 'Reserve a table',
          description: {
            span0:
              'For the period 10-20 of August we accept reservations until 7 pm.',
            span1: 'For parties larger than 8 guests &',
            span2:
              'same-day reservations, please contact the restaurant by calling',
            span3: 'after 14:00.',
            span4: 'Your table will be available',
            span5: 'for 15 minutes from the reservation time.',
            span6:
              'In case you would like to change or cancel your reservation, please contact us as soon as possible.',
          },
        },
        two: {
          title: 'Contact information',
          description:
            'Customers may have special requests such as dietary restrictions or seating preferences. The restaurant will make every effort to accommodate these requests if made in advance.',
        },
        three: {
          title: 'Reservation Confirmation',
          description: 'Please verify that everything is in order.',
          span1: 'Dear Pardalo Katsiki, ',
          span2: 'i would like to make a reservation for ',
          span3: 'people at ',
          span4: 'and time ',
          span5: 'My telephone and email is ',
          span6: 'I will keep an eye out for email confirmation!',
          span7: 'I made some comments:',
          span8: 'Best,',
        },
      },
      people: {
        label: 'Number of people',
        placeholder: 'Choose party size',
      },
      date: {
        label: 'Reservation date',
        placeholder: 'Select date',
      },
      time: {
        label: 'Reservation time',
        placeholder: 'Select time',
      },
      name: {
        label: "What's your name?",
        placeholder: '',
      },
      email: {
        label: 'What is your email address?',
        placeholder: 'example@email.gr',
      },
      countryCode: {
        label: 'Country code',
        placeholder: 'Please select',
      },
      telephone: {
        label: 'Telephone number',
        placeholder: '123-456-789',
      },
      comments: {
        label: 'Comments',
        placeholder: '',
      },
    },
    welcome: {
      message: {
        h1: 'Opening 2024',
        p1: 'We are proud to announce you ',
        p2: 'our grand grand opening of our restaurant',
        p3: 'on Saturday 11/5!',
        p4: 'See you soon!',
        p5: 'Enjoy!',
      },

      close: 'Close',
      h: 'Καλωσορίσατε στο Παρδαλό κατσίκι!',
    },
    lefkas: {
      title: 'Lefkas',
      meta: {
        description: 'Explore Lefkas.',
      },
      button: {
        projects: 'Projects',
        map: 'Map',
      },
      sidebar: {
        start: 'Go to start',
        projects: 'Projects',
      },
      header: {
        p1: 'Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        button: 'Click me',
      },
    },
    navigation: {
      localArea: 'Local area',
      home: 'Home',
      reserve: 'Reserve a table',
      lefkas: 'Blog',
      restaurant: 'Our restaurant',
      menu: {
        name: 'The menu 2024',
        caption: 'Coming soon!',
      },
      location: 'Find us',
      contact: 'Say Hello!',
    },
    home: {
      title: 'Home',
      village: {
        meta: {
          description: 'Our village Karya',
        },
        head: 'A little bit',
        title: 'Our village Karya',
        tail: 'Karya',
        p: 'Narrow cobbled streets, which go in different directions and dissapear between the numerous traditional built houses. Historical small churches, windmills are some of the sightseeings a visitor can see in the old town of Lekfas, Karya. In its center, the main square with the old plain trees and the natural source of water next to it create emotions that are difficult to forget.',
      },
      lentils: {
        meta: {
          description: 'Lentils of Eglouvi',
        },
        head: 'Taste famous quality',
        title: 'Lentils of Eglouvi',
        tail: '',
        p: 'The lentils are grown on the plateau of Agios Donatos, west of Egklouvi, the most mountainous village of the island. There are findings such as pieces of prehistoric tools, mostly made of flint, which were used until the last century to thresh the lentils that indicate that this pulse has been cultivated on the plateau for centuries. On the 6th  of August, on the celebration day of the Transfiguration of Jesus and on the eve of the celebration of Agios Donatos, the Feast of Lentils takes place on the homonymous plateau.',
      },
      lathyria: {
        meta: {
          description: 'Lathyrus of Karya',
        },
        head: 'Traditionaly made',
        title: 'Lathyrus of Karya',
        tail: '',
        p: 'Lathyria is a legume that is cultivated at the prairie of Karya that is located at the center of the island. Without the use of pesticides and fertilisers makes lathyria a perfectly biological product. Each year a part of the production is kept to be used as seeds for the next year. The seeding is done in May and the beans are ready to be harvested after about 70 days, roughly by mid July. Lathyria are washed and processed by a special machine that cracks them into small pieces. Then they are sieved until their skin is completely removed.',
      },
      embroideries: {
        meta: {
          description: 'Embroideries of Karya',
        },
        head: 'Explore our tradition on',
        title: 'Embroideries of Karya',
        tail: '',
        p: 'Karya is also famous for the wonderful needlework. The product of the one of a kind knitting technique of Karya that was invented by an extraordinary woman namely Maria Stavraka also knwon as Koutsohero. Although she had both hands partialy disabled, she managed to prove that the internal strengh and the love for ones work can overcome all disabilities.',
      },
    },

    restaurant: {
      title: 'The Restaurant',
      menu: 'The menu 2024',
      contact: 'Find us',
      ourFood: {
        title: 'Our food',
        p: 'Based on our tradition and passion, we create recipes that have lasted in time and become part of us. Goat cooked for 5 hours in the oven with thyme and rosemary, rooster in the pot with bucatini pasta and real fresh traditional moussaka in the clay are some of our dishes. Our raw materials including virgin olive oil and our garden aromatic herbs are some of the basic ingredients we need in order to offer you homemade traditional food. ',
      },
      ourVision: {
        title: 'Our restaurant',
        p: 'Pardalo Katsiki is located in the main highland village of lefkada, Karya. The village was build on the verdant slopes of the  mountain, in the center of the island renowned for its excellent climate and inherent natural beauty. The restaurant is accomodated by an old stone bulding in the heart of the village, next to a huge plane tree square. Pardalo Katsiki is open every day, ready to welcome you with his funky recipies, nice coffee and serve you by smiley people.',
      },
      contactUs: {
        meta: {
          description:
            'You can book a table or you can ask about events, tours, activities in our village Karya',
        },
        title: 'Contact us',
        h1: 'You want to send a hello?',
        h2: 'You can use the form below or you can call us at +302645041769.',
        failureMessage:
          'Oups, something went wrong and we could not receive your message.',
        successMessage:
          'Thank you for taking the time to send us a message. You can also call us on +302645041769!',
        close: 'Close',
      },
    },
    contactForm: {
      submit: 'Send message',
      validation: {
        required: 'Required',
        name: 'Must be 3 characters or more',
        email: 'Invalid email address',
        number: 'Must be a number',
        numberOfDigits: 'Must be at least 7 digits.',
      },
      name: {
        label: "What's your name?",
        placeholder: '',
      },
      email: {
        label: 'What is your email address?',
        placeholder: 'example@email.com',
      },
      phoneNumber: {
        label: "What's your phone number?",
        placeholder: '123-456-789',
      },
      message: {
        label: 'Tell us something that you like to know',
        placeholder: 'What are the events in Karya, Lefkas this summer?',
      },
    },
    menu: {
      navigation: {
        appetizers: 'Appetizers',
        salads: 'Salads',
        main: 'Main',
        grilled: 'Traditional grilled',
        daily: 'Today',
      },
      smallpopup: {
        p: 'Ask us for the daily dishes!',
      },
      footer: {
        message1: 'Split peas. chickpeas and lentils are produced in Lefkas.',
        message2: 'In foods and salads we use extra virgin olive oil.',
        message3: 'Meat and paultry are local products.',
      },
      dailyDishes: {
        ask: 'Ask us for the daily dishes!',
      },
      appetizers: {
        meta: {
          description: 'Our appetizers menu',
        },
      },
      salads: {
        meta: {
          description: 'Our salads menu',
        },
      },
      main: {
        meta: {
          description: 'Our main menu',
        },
      },
      grilled: {
        meta: {
          description: 'Our traditional grilled menu',
        },
      },
      daily: {
        meta: {
          description: 'Daily dishes',
        },
      },
    },
    notFound: {
      info: 'Oups, looks like there is no food here. Would you like to contact us instead?',
    },

    maintenance: {
      heading: 'The Pardalo Katsiki website is under maintenance.',
      contactUs: 'Would you like to contact us?',
      contactInfo: 'Send us a message to {email} or make a call {telephone}',
    },
    address: {
      header: 'Find us',
      storeOpen: {
        title: 'Open hours Mon-Sun',
        hours: '12:00pm-23:30',
      },
      callUs: 'Call us',
      visitUs: 'Visit us',
      address: 'Karya, Lefkas, 31080, Greece',
      emailUs: 'Email us',
      directions: 'Directions',
      contactForm: 'Contact form',
      reserve: 'Reserve a table',
    },
  },
  el: {
    meta: {
      defaultTitle: 'Το Παρδαλό Κατσίκι Λευκάδα ',
      defaultDescription:
        'Απολαύστε παραδοσιακές συνταγές και ψητά κάτω από την σκιά των πλατάνων.',
      defaultKeywords:
        'εστιατόριο, φαγητό, κατσίκι, παρδαλό, Λευκάδα, ταβέρνα, εστιατόριο, παραδοσιακό, παλαιώσεις',
      street: 'Καρυά Λευκάδας',
      locality: 'Λευκάδα',
      region: 'Iόνια Νησιά',
      postalCode: '31080',
      country: 'Ελλάδα',
    },
    welcome: {
      message: {
        h1: 'Opening 2024',
        p1: 'Ανοίγουμε τις πόρτες διάπλατες',
        p2: 'και σας περιμένουμε για αυτό το καλοκαιράκι',
        p3: 'από το σάββατο 11/5!',
        p4: 'Τα λέμε σύντομα!',
        p5: '',
      },
      reserve: {
        steps: {
          one: {
            title: 'Reservation details',
            description: '',
          },
          two: {
            title: 'Contact information',
            description: '',
          },
          three: {
            title: 'Acknowledge',
            description: '',
          },
        },
        info: {
          h1: 'Contact information',
          p: 'Customers may have special requests such as dietary restrictions or seating preferences. The restaurant will make every effort to accommodate these requests if made in advance.',
        },
        people: {
          label: 'Number of people',
          placeholder: 'Choose party size',
        },
        date: {
          label: 'Reservation date',
          placeholder: 'Choose date',
        },
        time: {
          label: 'Reservation time',
          placeholder: 'Choose time',
        },
        name: {
          label: "What's your name?",
          placeholder: '',
        },
        email: {
          label: 'What is your email address?',
          placeholder: 'example@email.gr',
        },
        countryCode: {
          label: 'Country code',
          placeholder: 'Please select',
        },
        telephone: {
          label: 'Telephone number',
          placeholder: '123-456-789',
        },
        comments: {
          label: 'Comments',
          placeholder: '',
        },
      },
    },
    lefkas: {
      title: 'Η Λευκάδα',
      meta: {
        description: 'Λίγα λόγια για την Λευκάδα.',
      },
      button: {
        projects: 'Άρθρα',
        map: 'Χάρτης',
      },
      sidebar: {
        start: 'Στην αρχή',
        projects: 'Άρθρα',
      },
      header: {
        p1: 'Το Lorem Ipsum είναι απλά ένα κείμενο χωρίς νόημα για τους επαγγελματίες της τυπογραφίας και στοιχειοθεσίας. Το Lorem Ipsum είναι το επαγγελματικό πρότυπο όσον αφορά το κείμενο χωρίς νόημα, από τον 15ο αιώνα, όταν ένας ανώνυμος τυπογράφος πήρε ένα δοκίμιο και ανακάτεψε τις λέξεις για να δημιουργήσει ένα δείγμα βιβλίου.',
        button: 'Πατησέ με',
      },
    },
    reserve: {
      successMessage:
        'Λάβαμε το αίτημα κράτησης σας. Μη διστάσετε να επικοινωνήσετε απευθείας με το Παρδαλό Κατσίκι στο +302645041769.',
      buttons: {
        next: 'Επόμενο',
        previous: 'Πίσω',
        submit: 'Στείλτε την κράτηση!',
        submitting: 'Στέλνεται...',
      },
      steps: {
        one: {
          title: 'Κάντε μία κράτηση 🐐',
          description: {
            span0:
              'Για το διάστημα 10-20 Αυγούστου δεχόμαστε κρατήσεις μέχρι και τις 7 μ.μ.',
            span1: 'Για παρέες μεγαλύτερες των 8 ατόμων &',
            span2:
              'για σημερινή κράτηση, παρακαλώ καλέστε μας από τις 14:00 και μετά στο',
            span3: '',
            span4: 'Το τραπέζι σας θα είναι διαθέσιμο',
            span5: 'για 15 λεπτά από την ώρα της κράτησης.',
            span6:
              'Σε περίπτωση που θα θέλατε να αλλάξετε ή να ακυρώσετε την κράτησή σας, παρακαλώ επικοινωνήστε μαζί μας όσο το δυνατόν συντομότερα.',
          },
        },
        two: {
          title: 'Προσωπικά στοιχεία',
          description:
            'Οι καλεσμένοι μας μπορεί να έχουν κάποιες διατροφικές ιδιαιτερότητες ή οτιδήποτε άλλο. Παρακαλούμε σημειώστε στα σχόλια αν χρήζει να το γνωρίζουμε εκ των προτέρων. Θα μας βοηθήσει να προετοιμαστούμε όσο το δυνατόν καλύτερα.',
        },
        three: {
          title: 'Επιβεβαίωση',
          description: 'Παρακαλώ ελέγξτε αν όλα είναι σωστά!',
          span1: 'Αγαπητό Παρδαλόκάτσικο, ',
          span2: 'θα ήθελα να κάνω μια κράτηση για ',
          span3: 'άτομα στις',
          span4: 'και ώρα',
          span5: 'Το τηλέφωνο και το email μου είναι ',
          span6: 'Θα έχω το νου μου να λάβω επιβεβαίωση μέσω email!',
          span7: 'Μην ξεχάσω, σας έγραψα και κάποια σχόλια:',
          span8: 'Με εκτίμηση,',
        },
      },
      reservation: {},
      people: {
        label: 'Αριθμός ατόμων',
        placeholder: 'Επιλέξτε αριθμό',
      },
      date: {
        label: 'Ημερομηνία κράτησης',
        placeholder: 'Επιλέξτε ημερομηνία',
      },
      time: {
        label: 'Ώρα κράτησης',
        placeholder: 'Επιλέξτε ώρα',
      },
      name: {
        label: 'Ποιό είναι το ονομάς σας;',
        placeholder: '',
      },
      email: {
        label: 'Ποιό είναι το email σας;',
        placeholder: 'example@email.gr',
      },
      countryCode: {
        label: 'Κωδικός χώρας',
        placeholder: 'Παρακαλώ επιλέξτε',
      },
      telephone: {
        label: 'Αριθμός τηλεφώνου',
        placeholder: '123-456-789',
      },
      comments: {
        label: 'Σχόλια κράτησης',
        placeholder: '',
      },
    },
    navigation: {
      restaurant: 'Το εστιατόριο',
      location: 'Η τοποθεσία μας',
      localArea: 'Ο τόπος μας',
      lefkas: 'Blog',
      title: 'Εστιατόριο',
      menu: {
        name: 'Το μενού 2024',
        caption: 'Έρχεται σύντομα!',
      },
      contact: 'Φόρμα επικοινωνίας',
      reserve: 'Κάντε μια κράτηση',
    },
    landing: {
      message: 'Καλωσήρθατε στο μαγαζί μας. Εμπρός. Περάστε.',
    },
    home: {
      title: 'Αρχική',
      village: {
        meta: {
          description: 'Η καρυά, το κεφαλοχώρι της Λευκάδας.',
        },
        title: 'Το χωριό μας η Καρυά',
        head: 'Λίγα λόγια για το',
        p: 'Αμέτρητα πλακόστρωτα δρομάκια που απλώνονται σε όλο τον οικισμό, υπέροχα παραδοσιακά πέτρινα σπίτια, αυλές με πεζούλια, διάσπαρτα ιστορικά εκκλησάκια, ανεμόμυλοι, ξερολιθιές είναι λίγα από τα αξιοθέατα που συνταντάει κάποιος επισκέπτης στο κεφαλοχώρι της Λευκάδας, την Καρυά. Στο κέντρο του χωριού η πλατεία δεσπόζει επιβλητικά αγκαλιάζοντας κάθε επισκέπτη στην σκιά και την δροσιά των υπεραιωνόβιων πλάτανών της.',
      },
      lentils: {
        meta: {
          description: 'Οι φακές Εγκλουβής, οι καλύτερες φακές του κόσμου.',
        },

        head: 'Λίγα λόγια για τις',
        title: 'Φακές Εγκλουβής',
        p: 'Για τη μοναδικότητα της η φακή Εγκλουβής έχει διακριθεί παγκοσμίως. Ο σπόρος της Φακής Εγκλουβής είναι χαρακτηριστικά μικρός, ανομοιόμορφος και έχει διάφορα χρώματα από σκούρο καφέ σε ανοιχτό κίτρινο και από μαύρο σε ανοιχτόχρωμο με σκούρες κηλίδες. Καλλιεργείται με τον ίδιο τρόπο από γενιά σε γενιά και δεν χρησιμοποιούνται κανενός είδους χημικά λιπάσματα και ζιζανιοκτόνα κατά την διάρκεια της παραγωγής. Η εργασία είναι επίπονη και η απόδοση της σοδειάς εξαρτάται απόλυτα από τον καιρό.',
      },
      lathyria: {
        meta: {
          description: 'Λαθύρια Καρυάς, μια τροφή πλούσια σε βιταμίνες.',
        },

        head: 'Λίγα λόγια για τα',
        title: 'Λαθύρια',
        p: 'Το Λαθύρι ανήκει στην κατηγορία των ψυχανθών και καλλιεργείται στον κάμπο της Καρυάς χωρίς την παραμικρή χρήση φυτοφαρμάκων και λιπασμάτων. Ευδοκιμεί εξαιτίας των κατάλληλων εδαφικών συστατικών και των ιδιαίτερων καιρικών συνθηκών της περιοχής. Είναι ένα όσπριο που μαγειρεύεται εύκολα και χαρακτηρίζεται από τις ευεργετικές του ιδιότητες για το πεπτικό σύστημα.',
      },
      embroideries: {
        meta: {
          description: 'Η κουτσοχέρο δημιουργεί πολιτισμό στην Καρυά Λευκάδας.',
        },

        head: 'Λίγα λόγια για τα',
        title: 'Κεντήματα',
        p: ' Οι κάτοικοι τους χωριού μας έχουν να επιδείξουν ένα ιδιαίτερο πολιτισμό ο οποίος αποτελεί παρακαταθήκη για όλο το νησί της Λευκάδας. Η πιο ιδιαίτερη, έκφανση του καρσάνικου  πολιτισμού, η οποία ενσωματώθηκε ολοκληρωτικά στον πολιτισμό του νησιού, είναι το Καρσάνικο Κέντημα.',
      },
    },
    contactForm: {
      submit: 'Αποστολη μηνυματος',
      validation: {
        required: 'Απατείται',
        name: 'Τουλάχιστον 3 χαρακτήρες',
        email: 'Η διεύθυνση δεν είναι έγκυρη.',
        number: 'Πρέπει να είναι αριθμός.',
        numberOfDigits: 'Πρέπει τουλάχιστον 7 νούμερα.',
      },
      name: {
        label: 'Το ονομά σας',
        placeholder: '',
      },
      email: {
        label: 'Ποιό είναι το email σας;',
        placeholder: 'example@email.com',
      },
      phoneNumber: {
        label: 'Τηλέφωνο επικοινωνίας;',
        placeholder: '123-456-789',
      },
      message: {
        label: 'Γράψτε μας το μήνυμα σας',
        placeholder: '',
      },
    },
    restaurant: {
      title: 'Το εστιατόριο',
      meta: {
        description:
          'Δημιουργούμε παραδοσιακές συνταγές και απολαυστικούς μεζέδες με αγνά υλικά και μεράκι.',
      },
      menu: 'Το μενού 2024',
      contact: 'Βρείτε μας',
      ourFood: {
        title: 'Το φαγητό μας',
        p: 'Με βάση την παράδοση και το μεράκι δημιουργούμε συνταγές που άντεξαν στον χρόνο και γίνανε κομμάτι του πολιτισμού μας. Κατσικάκι σιγομαγειρεμένο στο φούρνο με θυμάρι και δενδρολίβανο, κοκκοτός αλανιάρης, δικιά μας εκτροφής, στην κατσαρόλα με τρυπητό χοντρό μακαρόνι αλλά και φρέσκος παραδοσιακός μουσακάς στο πήλινο είναι μερικά από τα φαγητά μας. Το παρθένο ελαιόλαδο παραγωγής μας και τα αρωματικά φυτά του κήπου μας είναι μερικά από τα βασικά υλικά για να σας προσφέρουμε σπιτικό χωριάτικο φαγητό.',
      },
      ourVision: {
        title: 'Το εστιατόριο',
        p: 'Το παρδαλό κατσίκι βρίσκεται στο κεφαλοχώρι της Λευκάδας, την Καρυα. Ένα χωριό κτισμένο στις καταπράσινες πλαγιές ενός βουνού στο κέντρο της Λευκάδας, το οποίο φημίζεται για το άριστο κλίμα του και το απαράμιλλης ομορφιάς φυσικό περιβάλλον του. Το πετρόχτιστό μας ταβερνάκι που βρίσκεται στην καρδιά αυτού του χωριού, μέσα στα πλατάνια και το βουητό από τα τρεχούμενα νερά φιλοξενεί μια ομάδα από ανθρώπους γεμάτους μεράκι και ζωντάνια έτοιμους να δημιουργήσουν και να σας σερβίρουν απολαυστικούς μεζέδες.',
      },
      contactUs: {
        title: 'Επικοινωνήστε μαζί μας',
        meta: {
          description: 'Επικοινωνήστε μαζί μας!',
        },

        h1: 'Θέλετε να επικοινωνήστε μαζί μας;',
        h2: 'Εάν θέλετε να μας πείτε ένα γειά μπορείτε να χρησιμοποιήσετε την φόρμα παρακάτω και εμείς θα σας απαντήσουμε σύντομα.',
        h3: 'Ρωτήστε μας και θα σας απαντήσουμε.',
        p: 'Θέλετε να κάνετε μια κράτηση; Πατήστε εδώ.',

        failureMessage:
          'Ωχ, κάτι πήγε στραβά και δεν καταφέραμε να πάρουμε το μήνυμα σου!',
        successMessage:
          'Eυχαριστούμε για το μηνυμά σας. Μπορείτε επίσης να επικοινωνήσετε μαζί σας στο +302645041769.',
        close: 'Κλείσιμο',
      },
    },
    address: {
      storeOpen: {
        title: 'Ώρες λειτουργίας Δευ-Κυρ',
        hours: '12:00μμ-23:30μμ',
      },
      header: 'Βρείτε μας',
      openHours: 'Είμαστε ανοιχτά κάθε μέρα 12:00πμ - 23:00μμ',
      callUs: 'Καλέστε μας',
      visitUs: 'Επισκεφτείτε μας',
      address: 'Καρυά, Λευκάδα, 31100, Ελλάδα',
      emailUs: 'Στείλτε email',
      contactForm: 'Φόρμα επικοινωνίας',
      reserve: 'Κάντε κράτηση',
      directions: 'Google maps',
      contactUs: 'Επικοινωνηστε μαζι μας',
    },
    menu: {
      navigation: {
        appetizers: 'Ορεκτικά',
        salads: 'Σαλάτες',
        main: 'Κυρίως',
        grilled: 'Παραδοσιακά ψητά',
        daily: 'Σημέρα',
      },
      smallpopup: {
        p: 'Ρωτήστε μας για τα πιάτα ημέρας!',
      },
      footer: {
        message1:
          'Η φάβα, τα ρεβύθια και οι φακές είναι προϊόντα της λευκαδίτικης γης.',
        message2:
          'Στα φαγητά και στη σαλάτα χρησιμοποιούμε αγνό παρθένο ελαιόλαδο.',
        message3: 'Τα κρέατα και τα πουλερικά είναι τοπικά προϊόντα',
      },
      dailyDishes: {
        ask: 'Ρωτήστε μας για τα πιάτα ημέρας!',
      },
      appetizers: {
        meta: {
          description: 'Το μενού μας για τα ορεκτικά',
        },

        item1: 'Φρεσκοκομμένες πατάτες',
        item1Desc: 'τηγανητές.',
        item2: 'Φάβα Καρυάς',
        item2Desc: 'φρέσκο κρεμμύδι, κάπαρη και λάδι βασιλικού.',
        item3: 'Μπακαλιάρος φιλέτο',
        item3Desc:
          'φάβα, λαδολέμονο λιαστής ντομάτας, πέστο ρόκας, άγριο κρίταμο, αγκινάρα.',
        item4: 'Μανιτάρια στο τηγάνι',
        item4Desc:
          'χειροποίητη μαγιονέζα τρούφας, έξτρα παρθένο ελαιόλαδο, πίκλα φινόκιο, αποξηραμένος κόλιανδρος.',
        item5: 'Μανούρι ψητό',
        item5Desc:
          'μαρμελάδα ντομάτας, δαμάσκηνο, φιλέτο πορτοκαλιού, καβουρδισμένοι ηλιόσποροι.',
        item6: 'Λουκάνικο χειροποίητο',
        item6Desc: 'πουρές ρεβιθιού, φυστικοβούτυρο, δυόσμος.',
        item7: 'Μπουγιουρντί',
        item7Desc: 'φέτα, γραβιέρα, λάδι πιπεριάς, μπούκοβο, σαλάμι Λευκάδας.',
        item8: 'Τζατζίκι',
        item8Desc: 'ψητό σκόρδο, αποξηραμένη ελιά.',
        item9: 'Ταρτάρ',
        item9Desc:
          'φιλέτο μόσχου με πίκλα αγγούρι, φρέσκο κρεμμυδάκι, αγγούρι και σόγια λεμόνι.',
        item10: 'Ψωμί',
        item10Desc: '',
      },
      salads: {
        meta: {
          description: 'Το μενού μας για τις σαλάτες',
        },
        item1: 'Ελληνική',
        item1Desc:
          'κάππαρη, ντομάτα, κρεμμύδι, αγγούρι, ελιές, κρίθινο παξιμάδι, φέτα ορεινής Τριχωνίδας, άγριο κρίταμο.',
        item2: 'Νησιώτικη',
        item2Desc:
          'χαρουποπαξίμαδο, άγριο κρίταμο, φρέσκο κρεμμυδάκι, ντομάτα, ντοματίνια, κατσικίσιο τυρί.',
        item3: 'Φακές',
        item3Desc:
          'φρέσκα μυρωδικά, κόλιανδρος, πίκλα κρεμμύδι, ντοματίνια, πιπεριές, βιολογικά λιαστά ντοματίνια, ελαιόλαδο με βαλσάμικο ξύδι.',
        item4: 'Παρδαλή',
        item4Desc:
          'σπανάκι, ρόκα, λόλα, σως από φρούτα εποχής, καβουρδισμένους κολοκυθόσπορους, ημίλιαστα ντοματίνια, ξηρό ανθότυρο Κρήτης.',
        item5: 'Σαλάτα κινόα',
        item5Desc:
          'ρόκα, σπανάκι, παντζάρι, τζίντζερ, χειροποίητη μαγιονέζα, dressing εσπεριδοειδών.',
        item6: 'Σαλάτα παντζάρι',
        item6Desc:
          'γλυκοπατάτα, κρεμμυδάκι, ραπανάκι, καρύδια, γαλομυζήθρα Κρήτης.',
      },
      main: {
        meta: {
          description: 'Μενού κυρίως πιάτων',
        },
        item0: 'Φρεσκοψημένος μουσακάς',
        item0Desc: 'μαγειρεμένος και σερβιρισμένος με τον παραδοσιακό τρόπο.',
        item1: 'Κοκοτός',
        item1Desc: 'αλανιάρης με λαζανάκι σε κόκκινη σάλτσα.',
        item2: 'Κατσίκι',
        item2Desc:
          'θυμάρι, δενδρολίβανο, βιολογικά λιαστά ντοματίνια, κατσικίσιο τυρί, πατατούλες φούρνου.',
        item3: 'Κριθαρότο',
        item3Desc:
          'ανάμεικτα μανιτάρια, βολίτης, λάδι τρούφας, πίκλα μανιτάρι, κρόκος Κοζάνης.',
        item4: 'Μοσχαρίσια μάγουλα',
        item4Desc:
          'σιγομαγειρεμένα, πουρές καρότου, πίκλα φινόκιο, καραμελωμένα καρότα, καβουρδισμένοι κολοκυθόσποροι.',
        item5: 'Χοιρινό κότσι',
        item5Desc:
          'σιγομαγειρεμένο 650γρ με γλυκόξινη σάλτσα και πουρέ ψητής μελιτζάνας.',
        item6: 'Rib-eye',
        item6Desc:
          '250γρ από μικρή ελληνική φάρμα, πατατούλες φούρνου, σως τσιμιτσούρι.',
        item7: 'Φιλεταρισμένα μπουτάκια ή στήθος κοτόπουλου',
        item7Desc: 'ελευθέρας βοσκής με πληγούρι και σάλτσα κόκκινου κρασιού.',
      },
      grilled: {
        meta: {
          description: 'Το μενού μας για τα παραδοσιακά ψητά μας',
        },
        item1: 'Μπιφτέκι από μοσχαρίσιο & πρόβειο κιμά',
        item1Desc: 'τυρί κρέμα με σχοινόπρασο και τηγανητές πατάτες.',
        item2: 'Μπριζολάκια χοιρινά λαιμού',
        item2Desc: 'με παραδοσιακό ηπειρώτικο τουρσί και πατάτες τηγανητές.',
        item3: 'Παϊδάκια',
        item3Desc: 'αρνίσια γάλακτος 0,5 κιλό/ 1 κιλό',
      },
      daily: {
        meta: {
          description: 'Μενού ημέρας',
        },
        item1: 'Φρέσκια μπύρα belka 500ml απαστερίωτη',
        item2: 'Argos star lager μπύρα μικρής ζυθοποιίας 500ml',
        item3: 'Λεμονάδα σπιτική με τζίντζερ και κανέλα',
        item4: 'Κρασί “Μηδέν άγαν” ποικιλία αγιωργίτικο 750ml',
        item5: 'Κολοκυθοκεφτέδες με σως φέτας',
        item6:
          'Σαλάτα κινόα με ρόκα, σπανάκι, παντζάρι, τζίντζερ, χειροποίητη μαγιονέζα και dressing εσπεριδοειδών.',
        item7: 'Δροσερή σούπα γκασπάτσο ντομάτας.',
        item8:
          'Rib-eye 220γρ. μικρής ελληνικής φάρμας με πατάτες μπέιμπι και σαλάτα',
        item9: 'Μπράουνι με τρεις σοκολάτες και παγωτό.',
        item10: 'Πορτοκαλόπιτα με παγωτό.',
        item11:
          'Βαζάκι με μους αρωματισμένη με ξύσμα λεμονιού, μπισκότο, χειροποίητη μαρμελάδα από φρούτα του δάσους.',
      },
    },
    notFound: {
      info: 'Oups, looks like there is no food here. Would you like to contact us instead?',
    },
    maintenance: {
      heading: 'Το site του παρδαλού κατσικιού είναι υπό κατασκευή.',
      contactUs: 'Θέλετε να επικοινωνήσετε μαζί μας;',
      contactInfo:
        'Στείλτε μας ένα email στο {email} ή τηλεφωνήστε μας στο {telephone}',
    },
  },
}
