import { gsap } from 'gsap'
import throttle from 'lodash.throttle'
import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'
import { colors, media } from '../../assets/Styles'
import {
  LocalizedNavLink,
  LocalizedRedirect,
  LocalizedRoute,
} from '../../modules'
import Embroideries from './Embroideries'
import Lathyria from './Lathyria'
import Lentils from './Lentils'
import Village from './Village'

const Styling = styled.div``
const Nav = styled.nav`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  width: calc(100% - 50px);
  height: auto;
  padding-bottom: 30px;
  z-index: 30;
`

const Ul = styled.ul`
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
`
const Li = styled.li`
  cursor: pointer;
  ${media.tablet`
    text-align: center;
  `};
`

const ItemLink = styled(LocalizedNavLink)`
  transition: all 0.5s ease-in-out;
  opacity: 0.5;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &.${(props) => props.activeClassName} {
    opacity: 1;
  }
`

ItemLink.defaultProps = {
  activeClassName: 'active',
}

const ItemRound = styled.div`
  margin: 0;
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  background-color: #f7f7f7;
  color: ${colors.darkGrey};
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  border: 1px solid #f7f7f7;
  border-radius: 50%;
  margin-right: 15px;
`

const ItemText = styled.div`
  display: inline-block;
  vertical-align: middle;
`
const H3 = styled.h3`
  margin: 0;
  color: ${colors.dark1};
  font-size: 85%;
`

const H1 = styled.h1`
  color: ${colors.dark1};
  margin: 0;
  font-size: 120%;
  text-transform: uppercase;
`

const links = [
  {
    path: 'local/village',
    title: 'home.village.title',
    head: 'home.village.head',
    tail: 'home.village.tail',
  },
  {
    path: 'local/embroideries',
    title: 'home.embroideries.title',
    head: 'home.embroideries.head',
    tail: 'home.embroideries.tail',
  },
  {
    path: 'local/lentils',
    title: 'home.lentils.title',
    head: 'home.lentils.head',
    tail: 'home.lentils.tail',
  },
  {
    path: 'local/lathyria',
    title: 'home.lathyria.title',
    head: 'home.lathyria.head',
    tail: 'home.lathyria.tail',
  },
]

const routes = [
  {
    pathname: 'local/village',
    Component: Village,
    exact: true,
  },
  {
    pathname: 'local/embroideries',
    Component: Embroideries,
    exact: true,
  },
  {
    pathname: 'local/lentils',
    Component: Lentils,
    exact: true,
  },
  {
    pathname: 'local/lathyria',
    Component: Lathyria,
    exact: true,
  },
]

const HomeRoutes = React.memo(({ windowSize }) => {
  const intl = useIntl()
  const wrapperRef = useRef()
  const history = useHistory()
  const location = useLocation()
  const [page, setPage] = useState(0)

  const next = () => {
    setPage((page) => Math.min(page + 1, routes.length - 1))
  }

  const previous = () => {
    setPage((page) => Math.max(page - 1, 0))
  }

  const removeLocalFromPath = (pathname) => {
    const array = pathname.split('/')
    array.splice(0, 2)
    return array.join('/')
  }

  const getRouteIndex = (pathname) => {
    return routes.findIndex(
      (route) => route.pathname === removeLocalFromPath(pathname),
    )
  }

  const handleScrollThrottled = useRef(
    throttle(
      (event) => {
        if (event.deltaX > 0) {
          next()
        }
        if (event.deltaX < 0) {
          previous()
        }
        if (event.deltaY > 0) {
          next()
        }
        if (event.deltaY < 0) {
          previous()
        }
      },
      2000,
      { leading: false },
    ),
  ).current

  const handleScroll = (event) => {
    handleScrollThrottled(event)
  }

  const firstUpdate = useRef(true)
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    history.push(`/${intl.locale}/${routes[page].pathname}`)
  }, [page])

  useEffect(() => {
    setPage(getRouteIndex(location.pathname))
  }, [])

  useEffect(() => {
    window.addEventListener('mousewheel', handleScroll)
    window.addEventListener('DOMMouseScroll', handleScroll)

    return () => {
      window.removeEventListener('mousewheel', handleScroll)
      window.removeEventListener('DOMMouseScroll', handleScroll)
    }
  }, [])

  const enterFromRightTimeline = (node) => {
    const image = node.querySelectorAll('.image-left')
    const timeline = gsap.timeline({ paused: true })

    return timeline
      .set(node, {
        x: windowSize.width,
      })
      .set(image, {
        opacity: 0,
      })
      .to(
        node,
        {
          duration: 1.2,
          x: 0,
          ease: 'power3.easeInOut',
        },
        '+=0.3',
      )
      .to(image, 1.2, {
        opacity: 1,
      })
  }

  const exitFromLeftTimeline = (node) => {
    const timeline = gsap.timeline({ paused: true })
    return timeline.to(node, {
      duration: 0.7,
      x: -windowSize.width,
      ease: 'power3.easeInOut',
    })
  }

  const enter = (node) => {
    const timeline = enterFromRightTimeline(node)

    timeline.play()
  }

  const exit = (node) => {
    const timeline = exitFromLeftTimeline(node)

    timeline.play()
  }

  return (
    <Styling ref={wrapperRef}>
      {routes.map(({ pathname, Component, exact }, index) => (
        <LocalizedRoute key={index.toString()} exact={exact} path={pathname}>
          {({ match }) => {
            return (
              <Transition
                appear
                in={match != null}
                onEntering={(node) => enter(node)}
                onExit={(node) => exit(node)}
                timeout={2000}
                mountOnEnter
              >
                {/* in prop applies only to Header and Rightbar */}
                <Component in={match != null} />
              </Transition>
            )
          }}
        </LocalizedRoute>
      ))}
      <LocalizedRedirect from="local" to="local/village" />
      <Nav>
        <Ul>
          {links.map((link, index) => (
            <Li key={index.toString()}>
              <ItemLink to={link.path}>
                <ItemRound>{index + 1}</ItemRound>
                <ItemText>
                  <H3>
                    <FormattedMessage id={link.head}>
                      {(message) => message}
                    </FormattedMessage>
                  </H3>
                  <H1>
                    <FormattedMessage id={link.title}>
                      {(message) => message}
                    </FormattedMessage>
                  </H1>
                </ItemText>
              </ItemLink>
            </Li>
          ))}
        </Ul>
      </Nav>
    </Styling>
  )
})

HomeRoutes.propTypes = {
  windowSize: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps, {})(HomeRoutes)
