import styled from 'styled-components'
import { colors, media } from '../../../assets/Styles'

export const MenuStyles = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .price {
    display: inline;
  }

  .category-container {
    border-bottom: 1px dotted ${colors.dark1};
    width: 780px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    min-height: ${window.innerHeight}px;
    ${media.tablet`
    width: 100%;`};
    ${media.phone`
    display: block;`};
  }

  .category-section {
    padding: 1rem;
    width: 70%;
    ${media.tablet`
    margin: 0 auto;`};
    ${media.phone`
    width: 100%;`};
  }

  .category-title {
    ${media.phone`
    margin-bottom: 2rem;`};
  }

  .item {
    margin-bottom: 1.5rem;
  }

  h5 {
    font-size: 120%;
    text-align: center;
    color: ${colors.dark1};
    margin: 1.5rem 0.5rem 0 0;
    /* display: ${(props) => (props.block ? 'block' : 'inline')}; */
  }

  h4 {
    display: ${(props) => (props.inline ? 'inline' : 'block')};
    color: ${colors.black};
    margin: 2rem 0.5rem 0.4em 0;
  }

  .description {
    font-size: 100%;
    /* https://stackoverflow.com/questions/2159843/how-to-make-div-height-increase-to-include-floated-image */
    overflow: hidden;
    color: ${colors.dark3};
    text-align: center;
    .price {
      margin-left: 5px;
      font-size: 115%;
      color: ${colors.dark1};
    }
  }

  .category-footer {
    font-family: 'Superclarendon';
    font-size: 70%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 730px;
    margin: 1rem auto;
    padding: 1rem;
    ${media.tablet`
    width: auto;`};
    ${media.phone`
    width: 100%;`};
  }
`

export const Container = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .price {
    display: inline-block;
  }
`
export const CategoryContainer = styled.div`
  border-bottom: 1px solid ${colors.dark1};
  width: 780px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  min-height: ${window.innerHeight}px;
  ${media.tablet`
    width: 100%;`};
  ${media.phone`
    display: block;`};
`

export const CategorySection = styled.section`
  padding: 1rem;
  width: 70%;
  ${media.tablet`
    margin: 0 auto;`};
  ${media.phone`
    width: 100%;`};
`

export const CategoryTitle = styled.h2`
  ${media.phone`
    text-align:center;
    margin-bottom: 2rem;`};
`

export const Item = styled.div`
  margin-bottom: 1.5rem;
`

export const SubItem = styled.div`
  margin: 0.5rem 0;
`

export const Title = styled.h5`
  font-style: italic;
  font-weight: bold;
  font-size: 110%;
  display: block;
  color: ${colors.dark1};
  margin: 1.5rem 0.5rem 0 0;
  display: ${(props) => (props.block ? 'block' : 'inline')};
`

export const GroupTitle = styled.h4`
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  color: ${colors.black};
  margin: 2rem 0.5rem 0.4em 0;
`

export const SubTitle = styled(Title)`
  font-size: 110%;
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  margin: 0.4rem 0.4rem 0 0;
`

export const TitleSpan = styled.span`
  font-size: 90%;
  display: inline-block;
  font-style: italic;
`
export const Description = styled.div`
  font-family: 'Superclarendon';
  font-size: 110%;
  /* https://stackoverflow.com/questions/2159843/how-to-make-div-height-increase-to-include-floated-image */
  overflow: hidden;
  color: ${colors.dark1};
  display: inline;
  .price {
    margin-left: 5px;
    display: inline-block;
    font-size: 115%;
    color: ${colors.dark1};
  }
`

export const CategoryFooter = styled.div`
  font-family: 'Superclarendon';
  margin-top: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 730px;
  margin: 1rem auto;
  padding: 1rem;
  ${media.tablet`
    width: auto;`};
  ${media.phone`
    width: 100%;`};
`
