import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Close from '../../assets/Close'
import { colors, device, sizes } from '../../assets/Styles'
import Footer from '../shared/Footer'
import Logo from '../shared/Logo'
import Actions from '../Restaurant/Location/Actions'
import Address from '../Restaurant/Location/Address'
import Nav from './Nav'

const Styling = styled.div`
  .wrapper {
    z-index: 100;
    position: fixed;
    height: 100%;
    top: 0;
    left: -100%;
    width: 100%;
    background: ${colors.light1};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.tablet} {
      width: 100%;
    }

    .container {
      max-width: 800px;
      width: 80%;
      padding: 15px;
      margin: 40px auto;
      @media ${device.tablet} {
        margin: 0 auto;
        height: 100%;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .footer-wrapper {
        display: flex;
        justify-content: center;
        @media ${device.tablet} {
          margin-top: 0;
        }
      }

      section {
        flex: 0 1 50%;
        @media ${device.tablet} {
          flex: none;
        }
      }

      main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media ${device.tablet} {
          display: block;
        }
      }
    }
  }
`
const CloseWrapper = styled.div`
  @media ${device.tablet} {
    margin-top: 3rem;
  }
`

const LogoWrapper = styled.div`
  margin-right: 2rem;
`

const Header = styled.header`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media ${device.tablet} {
    justify-content: center;
    margin-bottom: 0;
  }
`

const Menu = React.memo((props) => {
  const {
    windowSize: { width },
  } = props
  const history = useHistory()
  return (
    <Styling>
      <div className="wrapper">
        <div className="container">
          <Header>
            {width > sizes.phone && (
              <LogoWrapper>
                <Logo withPromo logoHeight="4rem" promoHeight="1.5rem" />
              </LogoWrapper>
            )}
            <CloseWrapper>
              <Close color="black" close={() => history.goBack()} />
            </CloseWrapper>
          </Header>

          <main>
            <section className="nav-section">
              <Nav />
              {width <= sizes.phone && <Actions />}
            </section>
            <section className="address-section">
              {width > sizes.phone && (
                <Address textAlign="right" fontSize="90%" />
              )}
            </section>
          </main>
          <div className="footer-wrapper">
            <Footer />
          </div>
        </div>
      </div>
    </Styling>
  )
})

Menu.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps, {})(Menu)
