import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import styled from 'styled-components'
import Nav from '../Menu/Nav'
import { BlurImageLoader } from '../shared/BlurImageLoader'
import LanguageSwitcher from '../shared/LanguageSwitcher'
import Logo from '../shared/Logo'

const Styling = styled.div.attrs({
  className: 'desktop-wrapper',
})`
  /* opacity: 0;
  visibility: hidden; */
  height: 100%;
  .desktop-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 25rem;
    .brand-wrapper {
      margin: 2rem 0;
    }

    .menu-section {
      height: 80%;
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      .row {
        .content {
          p {
            font-size: 90%;
            font-family: 'Superclarendon';
          }
        }
      }
      .nav-wrapper {
        width: 100%;
        /* Override css from Nav component. */
        nav {
          margin-bottom: 0;
          ul {
            li {
              justify-content: center;
              margin: 1.5rem 0;
              a {
                line-height: 1.6rem;
                display: flex;
                flex-direction: column;
                width: auto;
                font-size: 130%;
              }
            }
          }
        }
        .info {
          margin-top: 2rem;
          p {
          }
          .contact {
            margin-top: 1rem;
            a {
              display: block;
              text-decoration: underline;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
    footer {
      .language-wrapper {
        div {
          text-align: none;
          margin: 0;
          width: auto;
          padding: 1rem 0;
          border-left: none;
          ul {
            display: flex;
            li {
              a {
                width: auto;
                border-left: none;
                &.active {
                  border-bottom: 1px solid;
                }
                :last-child {
                  margin-left: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .images-container {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    .image-wrapper {
      flex: 1;
      .image {
        position: absolute;
        width: 50%;
        top: 50%;
        transform: translate(0%, -50%);
      }
      .image-right {
        right: -7%;
      }
      .image-left {
        left: -6%;
      }
    }
  }
`

const Desktop = (props) => {
  const windowSize = useSelector((state) => state.page.windowSize)
  const { locale } = props

  return (
    <Styling>
      <div className="desktop-container">
        <section className="menu-section">
          <div className="brand-wrapper">
            <Logo
              withPromo
              logoHeight="4rem"
              promoHeight="1.5rem"
              locale={locale}
            />
          </div>
          <div className="nav-wrapper">
            <Nav />
          </div>
          <div className="row text-row">
            <div className="content">
              <p>
                <FormattedMessage id="address.storeOpen.title">
                  {(message) => message}
                </FormattedMessage>
              </p>
              <p>
                <FormattedMessage id="address.storeOpen.hours">
                  {(message) => message}
                </FormattedMessage>
              </p>
            </div>
          </div>
        </section>
        <footer>
          <div className="language-wrapper">
            <LanguageSwitcher />
          </div>
        </footer>
      </div>
      <div className="images-container">
        <div className="image-wrapper">
          <BlurImageLoader
            className="image image-left"
            name="tavern-entrance_e2xodk"
            alt="Pardalo Katsiki restaurant entrance"
          />
        </div>
        <div className="image-wrapper">
          <BlurImageLoader
            className="image image-right"
            name="square_mkqjmt"
            alt="Karya square"
          />
        </div>
      </div>
    </Styling>
  )
}

Desktop.propTypes = {
  locale: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  locale: state.page.locale,
})

export default Desktop
