import React from 'react'
import { FormattedMessage } from 'react-intl'
import SEO from '../SEO'
import { BlurImageLoader } from '../shared/BlurImageLoader'
import { Content, H1, ImageColumn, P, TextColumn, Wrapper } from './HomeStyles'

const Village = React.memo(() => {
  return (
    <Wrapper>
      <SEO
        title="home.village.title"
        description="home.village.meta.description"
      />

      <TextColumn alignItems="flex-end">
        <Content>
          <H1>
            <FormattedMessage id="home.village.title">
              {(message) => message}
            </FormattedMessage>
          </H1>
          <P>
            <FormattedMessage id="home.village.p">
              {(message) => message}
            </FormattedMessage>
          </P>
        </Content>
      </TextColumn>
      <ImageColumn alignItems="flex-start" maxWidth="550px">
        <BlurImageLoader name="village_ctltr9" />
      </ImageColumn>
    </Wrapper>
  )
})

export default Village
