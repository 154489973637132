import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { resetForm } from '../../../actions/contactActionCreators'
import Close from '../../../assets/Close'
import { LocalizedNavLink } from '../../../modules'
import SEO from '../../SEO'
import Footer from '../../shared/Footer'
import Background from '../Background'
import Confirmation from '../Confirmation'
import ContactForm from './ContactForm'
import { Container, H1, Header, P, Wrapper } from './ContactStyles'

const CloseWrapper = styled.div`
  align-self: center;
  margin-bottom: 2rem;
`

function Contact(props) {
  const { submitting, sendMessageSuccess, resetForm } = props
  const intl = useIntl()
  const history = useHistory()

  function reset() {
    resetForm()
    history.push(`/${intl.locale}/restaurant/location`)
  }

  return (
    <Wrapper>
      {(submitting || sendMessageSuccess || sendMessageSuccess === false) && (
        <Background />
      )}
      {sendMessageSuccess && <Confirmation success resetForm={reset} />}
      {sendMessageSuccess === false && (
        <Confirmation success={false} resetForm={resetForm} />
      )}
      <Container>
        <SEO
          title={intl.messages['restaurant.contactUs.title']}
          description={intl.messages['restaurant.contactUs.meta.description']}
        />

        <Header>
          <CloseWrapper>
            <LocalizedNavLink to="restaurant/location">
              <Close color="black" />
            </LocalizedNavLink>
          </CloseWrapper>
          <H1>
            <FormattedMessage id="restaurant.contactUs.h1">
              {(message) => message}
            </FormattedMessage>
          </H1>
          <P>
            <FormattedMessage id="restaurant.contactUs.h2">
              {(message) => message}
            </FormattedMessage>
          </P>
        </Header>

        <ContactForm />
      </Container>
      <Footer />
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    submitting: state.contact.submitting,
    sendMessageSuccess: state.contact.sendMessageSuccess,
  }
}

export default connect(mapStateToProps, { resetForm })(Contact)
