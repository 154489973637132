import { gsap } from 'gsap'

export default {
  enterTimeline(node) {
    const wrapper = node.querySelectorAll('.wrapper')
    const navSection = node.querySelectorAll('.nav-section')
    const addressSection = node.querySelectorAll('.address-section')

    const timeline = gsap.timeline({ paused: true })

    return timeline
      .set(navSection, {
        opacity: 0,
      })
      .set(addressSection, {
        opacity: 0,
      })
      .to(wrapper, {
        duration: 0.7,
        left: 0,
        ease: 'power3.easeInOut',
      })
      .to(
        navSection,
        {
          duration: 0.3,
          opacity: 1,
          ease: 'power3.easeInOut',
        },
        '-=0.3',
      )
      .to(
        addressSection,
        {
          duration: 0.3,
          opacity: 1,
          ease: 'power3.easeInOut',
        }
      )
  },

  exitTimeline(node) {
    const wrapper = node.querySelectorAll('.wrapper')

    const timeline = gsap.timeline({ paused: true })

    return timeline.to(
      wrapper,
      {
        duration: 0.7,
        x: '-100%',
        ease: 'power2.easeInOut',
      }
    )
  },
}
