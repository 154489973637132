import { Power3 } from 'gsap'
import 'mapbox-gl/dist/mapbox-gl.css'
// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
import mapboxgl from 'mapbox-gl';
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactMapGL, { FlyToInterpolator, Marker } from 'react-map-gl'
import styled from 'styled-components'
import Pin from '../../shared/Pin'

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibWFyaW5vc3phayIsImEiOiItdjZkVFQwIn0.Fb4w2Uwb-fzwvkhKAHUdqg'
const MAPBOX_STYLE_URL =
  'mapbox://styles/marinoszak/ckjd361g0ae3519l98mpkoyc2'

const MapWrapper = styled.div`
  margin-right: 50px;
`
const LONGITUDE = 20.650328
const LATITUDE = 38.7599

const Map = props => {
  const [viewport, setViewport] = useState({
    latitude: 38.664,
    longitude: 20.650328,
    zoom: 10.5,
    bearing: 0,
    pitch: 30,
    width: 500,
    height: 500,
  })
  const [settings] = useState({
    dragPan: true,
    dragRotate: false,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: true,
    minZoom: 10,
    maxZoom: 17,
    minPitch: 0,
    maxPitch: 30,
    dragToRotate: false,
  })

  let {
    windowSize: { width, height },
  } = props

  useEffect(() => {
    if (width <= 780) height = (height * 6) / 8
    if (width > 780 && width <= 1440) width /= 2
    if (width > 1440) width = 780

    setViewport({ ...viewport, width, height })
  }, [width, height])

  const flyTo = () => {
    setViewport({
      ...viewport,
      pitch: 15,
      zoom: 15,
      latitude: 38.7599,
      longitude: 20.650328,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 2000,
      transitionEasing: Power3.easeInOut,
    })
  }

  const renderMarker = () => {
    return (
      <Marker
        longitude={LONGITUDE}
        latitude={LATITUDE}
        onClick={() => flyTo(38.7599, 20.650328)}
      >
        <Pin size={20} onMarkerClick={() => flyTo()} />
      </Marker>
    )
  }

  return (
    <MapWrapper>
      <ReactMapGL
        {...viewport}
        {...settings}
        mapStyle={MAPBOX_STYLE_URL}
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      >
        {renderMarker()}
              

      </ReactMapGL>
    </MapWrapper>
  )
}

Map.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

export default Map
