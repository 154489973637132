import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
// import Play from '../../../assets/Play'
import { colors } from '../../../assets/Styles'
// import { LocalizedNavLink } from '../../../modules'
import Media from '../Project/Media'

const Styling = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  .media-container {
    position: relative;
    cursor: pointer;
    &:hover span svg {
      opacity: 1;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      width: 100px;
      height: 100px;
      z-index: 1;
      cursor: pointer;
    }
  }
  h1 {
    margin: 0;
    margin-top: 2rem;
  }
  .location-wrapper {
    margin: 0;
    font-size: 90%;
    color: ${colors.red};
  }
  p {
    margin: 0 2rem 4rem;
  }
`

const FirstLetter = styled.span`
  font-size: 4rem;
  margin-right: 0.6rem;
  height: 100%;
  padding-top: 1.4rem;
  float: left;
`

const Body = ({ project: { id, title, location, body, image } }) => {
  const containerRef = React.useRef()
  return (
    <Styling ref={containerRef}>
      <div className="media-container">
        {/* <Span>
          <Play />
        </Span> */}
        <Media url={image.url} title={image.title} width="490" />
      </div>
      {/* <LocalizedNavLink
        to={`lefkas/map/projects/${id}/article`}
      ></LocalizedNavLink> */}
      <h3>{title}</h3>
      <div className="location-wrapper">{location}</div>
      <p>
        <FirstLetter>{body.charAt(0)}</FirstLetter>
        {body.slice(1)}
      </p>
    </Styling>
  )
}

Body.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
}
export default Body
