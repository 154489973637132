import moment from 'moment'
import React, { useState } from 'react'
import { TimePicker } from 'antd'
import { useIntl } from 'react-intl'

const TimePickerWithFormik = ({
  id,
  form: { setFieldValue, setFieldTouched, values },
  field,
  ...props
}) => {
  const [focus, setFocus] = useState(false)
  const [time, setTime] = useState('')
  const handleDateChange = (time) => {
    const timeString = time.$d.toLocaleTimeString('EN-GB')
    // timeString is for Airtable, in order to use the absolute Time
    setFieldValue('time', timeString)

    // timeObject is for Make and for the form to display formatting properly
    setFieldValue('timeObject', time)

    setTime(time)
    console.log(time)
  }
  const intl = useIntl()

  return (
    <TimePicker
      className="timepicker"
      value={values.timeObject}
      onChange={handleDateChange}
      format="HH:mm"
      disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
      minuteStep={10}
      hideDisabledOptions={true}
      showNow={false}
      placeholder={intl.messages['reserve.time.placeholder']}
      inputReadOnly={true}
    />
  )
}

export default TimePickerWithFormik
