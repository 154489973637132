import React from 'react'
import Select from 'react-select'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { colors, media } from '../../../assets/Styles'
import { Field } from 'formik'
import TimePickerWithFormik from './TimePickerWithFormik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const Styling = styled.div.attrs({
  className: 'form-container',
})`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;

  /* Ant design date picker */
  .datepicker {
    z-index: 1000;
    height: 54px;
    border: 1px solid rgb(170, 170, 170);
    font-style: italic;
    transition-property: border;
    transition-duration: 0.2s;
    border-radius: 2px;
    padding: 8px 10px;
    font-size: 90%;
    color: hsl(0, 0%, 50%);
  }
  .ant-picker {
    width: 100%;
    /* background: ${colors.light1}; */
    appearance: none;
    transition-property: border;
    transition-duration: 0.2s;
    border: 1px solid ${colors.light6};
    border-radius: 2px;
    padding: 15px 10px;
    outline: none;
    appearance: none;

    .ant-picker-input {
      input {
        font-style: italic;
      }
    }
  }

  .ant-picker-dropdown {
    .ant-picker-panel-container {
      font-size: 90%;
      font-style: italic;
      appearance: none;
      transition-property: border;
      transition-duration: 0.2s;
      border: 1px solid ${colors.light6};
      border-radius: 2px;
      padding: 15px 10px;
      outline: none;
      appearance: none;
    }
  }

  /* React select input */

  .react-select__control {
    /* background: ${colors.light1}; */
    font-size: 90%;
    font-style: italic;
    appearance: none;
    transition-property: border;
    transition-duration: 0.2s;
    border: 1px solid ${colors.light6};
    border-radius: 2px;
    padding: 8px 10px;
    outline: none;
    appearance: none;
    box-shadow: none;

    &:focus,
    &:hover {
      border: 1px solid ${colors.dark2};
    }

    .react-select__value-container {
      padding: 0;
    }

    .react-select__indicators {
      span {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .label {
    font-size: 90%;
    margin-bottom: 0.6rem;
    color: ${colors.black};

    span {
      color: ${colors.red};
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0 3rem 0;

    ${media.phone`
       margin: 0 0 1rem 0;;
    `}
    h1 {
      text-align: center;
      font-size: 250%;
      ${media.phone`
         font-size: 230%;
      `}
    }

    .info {
      padding: 1rem;
      background: #fff;
      margin: 0;

      ${media.phone`
        margin: 0 0 2rem 0;
      `}
      a {
        border-bottom: 1px dotted;
      }

      p {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 100%;
        width: fit-content;
      }

      .important {
        position: relative;
        display: inline-block;
        z-index: 2;

        &:before {
          content: '';
          z-index: -1;
          content: ' ';
          display: block;
          height: 90%;
          width: 100%;
          margin-left: -3px;
          margin-right: -3px;
          position: absolute;
          background: #fff2ae;
          transform: rotate(2deg);
          top: -1px;
          left: -1px;
          border-radius: 20% 25% 20% 24%;
          padding: 10px 3px 3px 10px;
        }
      }
    }
  }

  .react-select__menu {
    font-size: 90%;
    font-style: italic;
    appearance: none;
    transition-property: border;
    transition-duration: 0.2s;
    border: 1px solid ${colors.light6};
    border-radius: 2px;
    padding: 15px 10px;
    outline: none;
    appearance: none;

    &:focus,
    &:hover {
      border: 1px solid ${colors.dark2};
    }

    width: 100%;

    .react-select__menu-list {
      padding-bottom: 0;
      padding-top: 0;

      .react-select__option {
        border-bottom: 1px solid transparent;
        color: #55706c;
      }

      .react-select__option--is-focused {
        cursor: pointer;
        background-color: inherit;
        color: #00140f;
      }

      .react-select__option--is-selected {
        background-color: inherit;
        color: #00140f;
      }
    }
  }

  .row-container {
    display: flex;
    width: 100%;
    ${media.phone`
      flex-direction: column;
    `}
  }

  .field-wrapper {
    margin-right: 1rem;
    margin-bottom: 30px;
    position: relative;

    ${media.phone`
       margin: 0 0 10px 0;
    `}
    .error-wrapper {
      min-height: 1.2rem;
      margin-top: 0.2rem;

      span {
        background: ${colors.yellow};
        border-radius: 3px;
        padding: 0 0.3rem;
        font-size: 90%;
      }
    }

    .width-33 {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .last-child {
    margin-right: 0;
  }

  .width-33 {
    flex: 0 1 33%;
  }
`
const people = Array.from({ length: 8 }, (_, i) => {
  return { value: i + 1, label: (i + 1).toString() }
})

export default function Reservation({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  meta,
  field,
}) {
  const intl = useIntl()
  return (
    <Styling>
      <div className="header">
        <h1>{intl.messages['reserve.steps.one.title']}</h1>
        <div className="info">
          <p>
            <div className="important">
              {intl.messages['reserve.steps.one.description.span0']}
            </div>
          </p>
          <p>
            {intl.messages['reserve.steps.one.description.span1']}{' '}
            {intl.messages['reserve.steps.one.description.span2']}{' '}
            <a href="tel:+302645041769">+302645041769</a>{' '}
            {intl.messages['reserve.steps.one.description.span3']}
          </p>
          <p>
            {intl.messages['reserve.steps.one.description.span4']}{' '}
            <div className="important">
              {intl.messages['reserve.steps.one.description.span5']}
            </div>
          </p>
          <p>{intl.messages['reserve.steps.one.description.span6']}</p>
        </div>
      </div>
      <div className="row-container">
        <div className="field-wrapper width-33">
          <div className="label">
            {intl.messages['reserve.people.label']} <span>*</span>
          </div>
          <Select
            id="people"
            onBlur={() => setFieldTouched('people', true)}
            onChange={(value) => {
              return setFieldValue('people', value)
            }}
            value={values.people}
            name="people"
            options={people}
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.value}
            classNamePrefix="react-select"
            placeholder={intl.messages['reserve.people.placeholder']}
            inputProps={{ readOnly: true }}
            isSearchable={false}
          />
          <div className="error-wrapper">
            {errors.people && touched.people && (
              <span>{errors.people.value}</span>
            )}
          </div>
        </div>
        <div className="field-wrapper width-33">
          <div className="label">
            {intl.messages['reserve.date.label']} <span>*</span>
          </div>
          <DatePicker
            className={'datepicker'}
            id="date"
            selected={values.date}
            onChange={(date) => {
              if (date) {
                const localDate = new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate(),
                )
                setFieldValue('date', localDate)
              } else {
                setFieldValue('date', null)
              }
            }}
            minDate={new Date()} // Disables all past dates
            placeholderText="Select a date"
          />
          <div className="error-wrapper">
            {errors.date && touched.date && <span>{errors.date}</span>}
          </div>
        </div>
        <div className="field-wrapper width-33 last-child">
          <div className="label">
            {intl.messages['reserve.time.label']} <span>*</span>
          </div>
          <Field component={TimePickerWithFormik} />
          <div className="error-wrapper">
            {errors.time && touched.time && <span>{errors.time}</span>}
          </div>
        </div>
      </div>
    </Styling>
  )
}
