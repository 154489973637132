import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import contactReducer from './contactReducer'
import pageReducer from './pageReducer'
import projectsReducer, * as fromProjects from './projectsReducer'

export default combineReducers({
  projects: projectsReducer,
  contact: contactReducer,
  form: formReducer,
  page: pageReducer,
})

export const getProjects = state => fromProjects.getProjects(state.projects)
export const getSelectedProject = state =>
  fromProjects.getSelectedProject(state.projects)
