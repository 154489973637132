import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { setLocale } from '../../actions/pageActionCreators'
import { BlurImageLoader } from '../shared/BlurImageLoader'
import LanguageSwitcher from '../shared/LanguageSwitcher'
import Logo from '../shared/Logo'
import Slide from '../shared/Slide'

const Styling = styled.div.attrs({
  className: 'welcome-wrapper',
})`
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  top: -${(props) => (props.windowSize.height * 70) / 100 + 200}px;
  position: absolute;
  width: 100%;
  height: ${(props) => props.windowSize.height}px;
  left: 0;

  .welcome-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;

    .row {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .brand-row {
      padding-top: 3rem;
      flex: 0 1 200px;
    }
    .brand {
      margin-top: 3rem;
    }
    .text-row {
      margin: 2rem 0 0 0;
      .content {
        text-align: center;
        position: relative;
        margin: 0.5rem;
        p {
          font-family: 'Superclarendon';
          font-size: 70%;
        }
        h2 {
          margin: 0;
          font-size: 100%;
        }
        h1 {
          margin: 0;
          font-size: 110%;
          display: block;
        }
      }
    }
    .language-row {
      z-index: 300;
    }
    .image-row {
      padding-top: 1rem;
      width: 100%;
      display: block;
    }
  }
`
const Welcome = (props) => {
  const { windowSize, welcome, closeWelcome, locale, setLocale } = props
  return (
    <Slide in={welcome}>
      <Styling
        windowSize={windowSize}
        onTouchMove={() => closeWelcome()}
        onScrollCapture={() => closeWelcome()}
        onDrag={() => closeWelcome()}
        onClick={() => closeWelcome()}
      >
        <div className="welcome-container">
          {/* <div className="row brand-row">
            <Logo
              display="inline"
              withPromo
              locale={locale}
              logoHeight="4rem"
              promoHeight="2rem"
            />
          </div> */}

          {/* <div className="row text-row opening"></div> */}
          <div className="row text-row">
            <div className="content brand">
              <Logo
                // display="inline"
                withPromo
                locale={locale}
                logoHeight="4rem"
                promoHeight="1.5rem"
              />
            </div>
            <div className="content">
              <h2>Opening May 2021</h2>
            </div>
            <div className="content">
              <p>
                <FormattedMessage id="address.storeOpen.title">
                  {(message) => message}
                </FormattedMessage>
              </p>
              <p>
                <FormattedMessage id="address.storeOpen.hours">
                  {(message) => message}
                </FormattedMessage>
              </p>
            </div>
            <div className="content">
              <LanguageSwitcher setLocale={setLocale} locale={locale} />
            </div>
          </div>
          <div className="row image-row">
            <BlurImageLoader
              className="image"
              name="restaurant-mobile_vlkovl"
            />
          </div>
        </div>
      </Styling>
    </Slide>
  )
}

Welcome.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  closeWelcome: PropTypes.func.isRequired,
  welcome: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
  locale: state.page.locale,
})

export default connect(mapStateToProps, { setLocale })(Welcome)
