export function toPrismicLocale(lc) {
  switch (lc) {
    case 'en':
      return 'en-gb'
    case 'el':
      return 'el-gr'
    default:
      throw new Error(`Unsupported language code: ${lc}`)
  }
}
