import { gsap } from 'gsap'

export default {
  fromRight(node) {
    const timeline = gsap.timeline()
    return timeline
      .set(node, {
        right: '-50px',
      })
      .to(
        node,

        {
          duration: 0.5,
          ease: 'power3.easeInOut',
          right: 0,
        },
        '=+0.5',
      )
  },

  toRight(node) {
    const timeline = gsap.timeline()
    return timeline.to(node, {
      duration: 0.5,
      ease: 'power3.easeInOut',
      right: '-50px',
    })
  },

  toTop(node) {
    const timeline = gsap.timeline()
    return timeline.to(node, {
      duration: 0.5,
      ease: 'power3.easeInOut',
      top: '-100px',
    })
  },

  fromTop(node) {
    const timeline = gsap.timeline()
    return timeline
      .set(node, {
        top: '-100px',
      })
      .to(
        node,
        {
          duration: 0.5,
          ease: 'power3.easeInOut',
          top: 0,
        },
        '=+1',
      )
  },
}
