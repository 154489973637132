import {
  REQUEST_SUBMIT,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  RESET_FORM,
} from '../constants/ActionTypes'

const contactInitialState = {
  submitting: false,
  sendMessageFailure: null,
  sendMessageSuccess: null,
}

const contact = (state = contactInitialState, action) => {
  switch (action.type) {
    case RESET_FORM:
      return {
        ...state,
        sendMessageSuccess: null,
      }
    case REQUEST_SUBMIT:
      return {
        ...state,
        submitting: true,
      }
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        submitting: false,
        sendMessageFailure: false,
        sendMessageSuccess: true,
      }
    case SEND_MESSAGE_FAILURE:
      return {
        ...state,
        submitting: false,
        sendMessageFailure: action.error,
        sendMessageSuccess: false,
      }

    default:
      return {
        submitting: state.submitting,
        sendMessageFailure: state.sendMessageFailure,
        sendMessageSuccess: state.sendMessageSuccess,
      }
  }
}

export default contact
