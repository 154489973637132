import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { device } from '../../assets/Styles'
import SEO from '../SEO'
import { BlurImageLoader } from '../shared/BlurImageLoader'
import { Content, H1, ImageColumn, TextColumn, Wrapper } from './HomeStyles'

const ContentStyled = styled(Content)`
  position: absolute;
  top: 10%;
  text-align: right;
  @media ${device.tablet} {
    position: relative;
    top: 0;
    text-align: center;
  }
`

const Lentils = React.memo(() => {
  return (
    <Wrapper>
      <SEO
        title="home.lentils.title"
        description="home.lentils.meta.description"
      />
      <ImageColumn alignItems="flex-end" maxWidth="550px">
        <BlurImageLoader name="lentils_fjqipj" alt="" />
      </ImageColumn>
      <TextColumn alignItems="flex-start">
        <ContentStyled>
          <H1>
            <FormattedMessage id="home.lentils.title">
              {(message) => message}
            </FormattedMessage>
          </H1>
          <p>
            <FormattedMessage id="home.lentils.p">
              {(message) => message}
            </FormattedMessage>
          </p>
        </ContentStyled>
      </TextColumn>
    </Wrapper>
  )
})

export default Lentils
