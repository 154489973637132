import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'
import { media } from '../../../../assets/Styles'
import NavItem from './NavItem'

const Styling = styled.nav`
  ${media.phone`
    margin-left: 0;`};
`

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent || 'center'};
  list-style: none;
`
const Li = styled.li`
  float: left;
  list-style: none;
`

const Nav = () => {
  return (
    <Styling marginLeft="1rem">
      <Ul>
        <Li>
          <NavItem to="restaurant/menu/appetizers">
            <FormattedMessage id="menu.navigation.appetizers">
              {(message) => message}
            </FormattedMessage>
          </NavItem>
        </Li>
        <Li>
          <NavItem to="restaurant/menu/salads">
            <FormattedMessage id="menu.navigation.salads">
              {(message) => message}
            </FormattedMessage>
          </NavItem>
        </Li>
        <Li>
          <NavItem to="restaurant/menu/main">
            <FormattedMessage id="menu.navigation.main">
              {(message) => message}
            </FormattedMessage>
          </NavItem>
        </Li>
        {/* <Li>
          <NavItem to="restaurant/menu/grilled">
            <FormattedMessage id="menu.navigation.grilled">
              {(message) => message}
            </FormattedMessage>
          </NavItem>
        </Li>
        <Li>
          <NavItem to="restaurant/menu/daily">
            <FormattedMessage id="menu.navigation.daily">
              {(message) => message}
            </FormattedMessage>
          </NavItem>
        </Li>*/}
      </Ul>
    </Styling>
  )
}

export default injectIntl(Nav)
