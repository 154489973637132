import React from 'react'
import { Transition } from 'react-transition-group'
import { fadeIn, fadeOut } from '../../animations'

const duration = {
  enter: 0,
  exit: 0,
}

const Fade = ({ children, ...props }) => {
  return (
    <Transition
      {...props}
      appear
      timeout={duration}
      mountOnEnter
      unmountOnExit
      onEnter={(node) => fadeIn(node, props.delay)}
      onExit={(node) => fadeOut(node)}
    >
      {children}
    </Transition>
  )
}
export default Fade
