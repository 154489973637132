import React from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { setLocale, setLoader } from '../../actions/pageActionCreators'
import { colors, device } from '../../assets/Styles'

const Wrapper = styled.div`
  text-align: center;
  margin-left: -1px;
  @media ${device.tablet} {
    text-align: none;
    margin: 0;
    width: auto;
    border-left: none;
  }
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  display: block;
  @media ${device.tablet} {
    display: inline-block;
  }
  a {
    width: 100%;
    display: inline-block;
    color: ${colors.dark1};

    &.active {
      border-left: 1px solid;
    }
    @media ${device.tablet} {
      width: auto;
      &:first-child {
        margin-right: 0.5rem;
      }
      float: left;

      &.active {
        border-left: none;
        border-bottom: 1px solid #222;
      }
    }
    &:hover {
      color: ${colors.dark3};
    }
  }
`
const areEqual = (prevProps, nextProps) => {
  return prevProps.locale === nextProps.locale
}

const LanguageSwitcher = ({ setLocale, setLoader }) => {
  const { pathname } = useLocation()
  const loc = useLocation()
  const { locale } = useIntl()

  const getMatchingRoute = (lc) => {
    let array = pathname.split('/')
    array[1] = lc
    const newPath = array.join('/')
    // const [, route] = pathname.split(lc)
    return newPath
  }
  const changeLocale = (lc) => {
    setLoader(true)
    setLocale(lc)
  }

  return (
    <Wrapper>
      <Ul>
        <Li>
          <NavLink
            to={getMatchingRoute('el')}
            onClick={() => changeLocale('el')}
          >
            GR
          </NavLink>
        </Li>
        <Li>
          <NavLink
            to={getMatchingRoute('en')}
            onClick={() => changeLocale('en')}
          >
            EN
          </NavLink>
        </Li>
      </Ul>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.page.locale,
  }
}

export default connect(mapStateToProps, { setLocale, setLoader })(
  LanguageSwitcher,
)
