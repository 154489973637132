import React, { useEffect } from 'react'
import Wizard from './Wizard/Wizard'
import Acknowledge from './Wizard/Acknowledge'
import Finish from './Wizard/Finish'
import Reservation from './Wizard/Reservation'
import Info from './Wizard/Info'

export default function Reserve() {
  const initialValues = {
    name: '',
    date: '',
    email: '',
    countryCode: '',
    telephone: '',
    people: '',
    time: '',
  }

  return (
    <React.Fragment>
      <Wizard initialValues={initialValues}>
        <Wizard.Page>
          {(props) => {
            return <Reservation {...props}></Reservation>
          }}
        </Wizard.Page>
        <Wizard.Page>
          {(props) => {
            return <Info {...props}></Info>
          }}
        </Wizard.Page>
        <Wizard.Page>
          {(props) => {
            return <Acknowledge {...props}></Acknowledge>
          }}
        </Wizard.Page>
      </Wizard>
    </React.Fragment>
  )
}
