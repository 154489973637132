import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import NavItem from './NavItem'

const routes = [
  {
    name: 'navigation.location',
    caption: null,
    path: 'restaurant/location',
    level: 2,
    isInactive: false,
  },
  {
    name: 'navigation.contact',
    caption: null,
    path: 'contact',
    level: 1,
    isInactive: false,
  },
  {
    name: 'navigation.localArea',
    caption: null,
    path: 'local/village',
    level: 1,
    isInactive: false,
  },
  {
    name: 'navigation.restaurant',
    caption: null,
    path: 'restaurant',
    level: 1,
    isInactive: false,
  },

  {
    name: 'navigation.reserve',
    caption: null,
    path: 'reserve',
    level: 2,
    isInactive: false,
    className: 'dotted',
  },

  {
    name: 'navigation.menu.name',
    caption: null,
    path: 'restaurant/menu/appetizers',
    level: 2,
    isInactive: false,
  },
]

const Wrapper = styled.nav`
  margin-bottom: 2rem;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .dotted {
    font-weight: bold;
  }
  .blinking {
    animation: blink-animation 2s infinite;
  }

  @keyframes blink-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export default function Nav() {
  return (
    <Wrapper>
      <ul>
        <TransitionGroup>
          {routes.map((route, index) => (
            <NavItem
              className={route.className ? route.className : ''}
              key={index.toString()}
              route={route}
              target={index}
            />
          ))}
        </TransitionGroup>
      </ul>
    </Wrapper>
  )
}
