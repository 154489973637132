import React from 'react'
import styled from 'styled-components'

const Styling = styled.footer.attrs({
  className: 'footer-wrapper',
})`
  .footer-container {
    text-align: center;
    margin: 3rem 0 1rem 0;
    font-size: 85%;
    span {
      a {
        font-family: 'Noto Serif', 'Helvetica Neue', Arial, Helvetica, Verdana,
          sans-serif;
        font-size: 100%;
        border-bottom: 1px solid;
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
`

const Footer = React.memo(() => {
  return (
    <Styling>
      <div className="footer-container">
        <span>
          Made with{' '}
          <span role="img" aria-label="coffee-symbol">
            ♥
          </span>{' '}
          by
          <br />
          Marinos Zakynthinos &{' '}
          <a
            target="_blank"
            rel="noopener noreferrer canonical"
            href="https://www.constantinadali.me"
          >
            Constantina Dali
          </a>
        </span>
      </div>
    </Styling>
  )
})

export default Footer
