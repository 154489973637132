import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../assets/Styles'

const Image = styled.img`
  transition-property: opacity;
  transition-duration: 0.3s;
  backface-visibility: hidden;
  margin: 0;
  height: 100%;
  width: 100%;
  @media ${device.tablet} {
    width: ${props => (props.width > 100 ? '100%' : '100px')};
    height: 100%;
  }
`

const Media = ({ url, title, width }) => {
  let src = null
  if (url) {
    src = `https:${url}?w=${width}`
  }
  return <Image src={src} title={title} width={width} />
}

Media.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default Media
