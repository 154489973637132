import requestManager from '../modules/requestManager'
import {
  SET_HOVERED,
  SET_SELECTED,
  REQUEST_PROJECTS,
  RECEIVE_PROJECTS,
  FETCH_PROJECTS_FAILURE,
} from '../constants/ActionTypes'

export const setSelected = id => {
  return {
    type: SET_SELECTED,
    id,
  }
}

export const setHovered = id => ({
  type: SET_HOVERED,
  id,
})

export const requestProjects = () => ({
  type: REQUEST_PROJECTS,
})

export const receiveProjects = data => {
  return {
    type: RECEIVE_PROJECTS,
    data,
  }
}

const fetchProjectsFailure = error => ({
  type: FETCH_PROJECTS_FAILURE,
  error,
})

export const fetchProjects = locale => {
  return dispatch => {
    dispatch(requestProjects())
    return requestManager
      .fetchProjects(locale)
      .then(response => dispatch(receiveProjects(response.data)))
      .catch(error => dispatch(fetchProjectsFailure(error)))
  }
}
