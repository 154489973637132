import PropTypes from 'prop-types'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { Redirect, Route } from 'react-router-dom'
import { flattenMessages } from './flattenMessages'

export const LocalizedRouter = ({
  children,
  RouterComponent,
  appStrings,
  locale,
}) => (
  <RouterComponent>
    {locale && (
      <Route path="/:locale([a-z]{2})">
        {({ location }) => {
          const { pathname } = location
          if (!pathname.includes(`/${locale}/`)) {
            return <Redirect to={`/${locale}/`} />
          }

          /**
           * Return Intl provider with default language set
           */
          return (
            <IntlProvider
              locale={locale}
              messages={flattenMessages(appStrings[locale])}
            >
              {children}
            </IntlProvider>
          )
        }}
      </Route>
    )}
  </RouterComponent>
)

LocalizedRouter.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  RouterComponent: PropTypes.func.isRequired,
  appStrings: PropTypes.object.isRequired,
}
