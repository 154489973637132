import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

export const LocalizedRoute = React.memo(
  props => {
    const { path } = props
    return <Route {...props} path={`/:locale/${path}`} />
  },
  () => true,
)
LocalizedRoute.propTypes = {
  path: PropTypes.string.isRequired,
}
