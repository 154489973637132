import React from 'react'
import { Transition } from 'react-transition-group'
import { enter, exit } from '../animations/index'
import { LocalizedRoute } from '../modules/index'
import Home from './Home/Home'
import Landing from './Landing/Landing'
import Lefkas from './Lefkas/Lefkas'
import Menu from './Menu/Menu'
import RightBar from './RightBar/RightBar'
import Header from './shared/Header'
import Reserve from './Restaurant/Reserve'
import Contact from './Restaurant/Contact/Contact'
import Location from './Restaurant/Location/Location'
import RestaurantMenu from './Restaurant/Menu/Menu'
import { useSelector } from 'react-redux'
import useGaTracker from '../hooks/useGaTracker'

import Restaurant from './Restaurant/Restaurant'

const routes = [
  {
    path: '',
    Component: Landing,
    exact: true,
  },
  // {
  //   path: 'lefkas',
  //   Component: LefkasRoutes,
  //   exact: false,
  // },
  {
    path: 'local',
    Component: Home,
    exact: false,
  },

  { path: 'restaurant', exact: true, Component: Restaurant },
  { path: 'restaurant/location', exact: true, Component: Location },
  { path: 'restaurant/menu', exact: false, Component: RestaurantMenu },

  {
    path: 'lefkas',
    Component: Lefkas,
    exact: false,
  },
  {
    path: 'reserve',
    Component: Reserve,
    exact: true,
  },
  {
    path: 'index',
    Component: Menu,
    exact: true,
  },
  { path: 'contact', exact: true, Component: Contact },
  {
    path: '(local|restaurant|lefkas)',
    Component: RightBar,
    exact: false,
  },
  {
    path: '(local|restaurant)',
    Component: Header,
    exact: false,
  },
]

const duration = {
  enter: 1000,
  exit: 700,
}

// const areEqual = (prevProps, nextProps) => {
//   return prevProps.windowSize.width === nextProps.windowSize.width
// }

function AppRoutes() {
  useGaTracker()

  const windowSize = useSelector((state) => state.page.windowSize)

  return (
    <React.Fragment>
      {routes.map(({ path, exact, Component }, index) => (
        <LocalizedRoute
          key={`${path}-${index.toString()}`}
          exact={exact}
          path={path}
        >
          {({ match }) => {
            return (
              <Transition
                appear
                in={match != null}
                onEntering={(node, appears) =>
                  enter(node, appears, path, windowSize)
                }
                onExit={(node) => exit(node, path, windowSize)}
                timeout={duration}
                mountOnEnter
                unmountOnExit={true}
              >
                {/* in prop applies only to Header and Rightbar */}
                <Component in={match != null} />
              </Transition>
            )
          }}
        </LocalizedRoute>
      ))}
    </React.Fragment>
  )
}

export default AppRoutes
