import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'

export const LocalizedRedirect = React.memo(props => {
  const { locale } = useIntl()

  const { from, to } = props
  return <Redirect from={`/${locale}/${from}`} to={`/${locale}/${to}`} />
})

LocalizedRedirect.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}
