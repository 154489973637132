import React from 'react'
import styled from 'styled-components'
import { LocalizedNavLink } from '../../modules'

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
`

const Burger = React.memo(() => {
  return (
    <LocalizedNavLink to="index">
      <Svg viewBox="10 0 50 50">
        <rect x={21} y={32} width={30} height={3} />
        <rect x={21} y={23} width={30} height={3} />
        <rect x={21} y={14} width={30} height={3} />
      </Svg>
    </LocalizedNavLink>
  )
})
export default Burger
