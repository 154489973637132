import { gsap } from 'gsap'

export default {
  fromLeft(target) {
    const timeline = gsap.timeline()
    return timeline.to(
      target,

      {
        duration: 0.7,
        left: 0,
        ease: 'power2.easeInOut',
      },
      '+0.5',
    )
  },

  toLeft(target) {
    const timeline = gsap.timeline()
    return timeline.to(target, {
      duration: 0.7,
      x: '-100%',
      ease: 'power2.easeInOut',
    })
  },
  scaleButtonUp(targets) {
    const timeline = gsap.timeline()
    return timeline
      .set(targets.buttonRef, {
        zIndex: 20,
      })
      .to(targets.buttonRef, {
        duration: 1.5,
        ease: 'power3.easeInOut',
        scale: 100,
      })
      .to(
        targets.containerRef,
        {
          duration: 0.2,
          ease: 'power3.easeInOut',
          opacity: 0,
        },
        '-=0.6',
      )

      .set(targets.buttonRef, {
        zIndex: 1,
        scale: 1,
      })
  },
}
