import { combineReducers } from 'redux'

import {
  SET_WINDOW_WIDTH,
  SET_LOCALE,
  SET_LOADER,
} from '../constants/ActionTypes'

const pageInitialState = {
  windowSize: { width: null, height: null },
  locale: '',
  localeIsSet: false,
  showLoader: true,
}

export const windowSize = (state = pageInitialState.windowSize, action) => {
  switch (action.type) {
    case SET_WINDOW_WIDTH:
      return action.windowSize
    default:
      return state
  }
}

export const locale = (state = pageInitialState.locale, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.locale
    default:
      return state
  }
}

export const showLoader = (state = pageInitialState.showLoader, action) => {
  switch (action.type) {
    case SET_LOADER:
      return action.boolean
    default:
      return state
  }
}

export default combineReducers({
  windowSize,
  locale,
  showLoader,
})
