import { Power3, TimelineLite } from 'gsap'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Transition } from 'react-transition-group'
import { sizes } from '../../assets/Styles'
import { LocalizedRoute } from '../../modules'
import List from './SideBar/List'
import Preview from './SideBar/Preview'

const routes = [
  // {
  //   path: 'lefkas',
  //   Component: Header,
  //   exact: true,
  // },
  {
    pathname: 'lefkas/map/projects',
    Component: List,
    exact: true,
  },
  {
    pathname: 'lefkas/map/projects/:projectId',
    Component: Preview,
    exact: true,
  },
  // {
  //   path: 'lefkas/map/projects/:projectId/article',
  //   Component: Article,
  //   exact: false,
  // },
]

const enterSidebarTimeline = node => {
  const timeline = new TimelineLite()
  return timeline.to(
    node,
    0.5,
    {
      ease: Power3.easeInOut,
      left: 0,
    },
    '=+0.8',
  )
}

const exitSidebarTimeline = (node, sidebarWidth) => {
  const timeline = new TimelineLite()
  return timeline.to(node, 0.8, {
    ease: Power3.easeInOut,
    left: `-${sidebarWidth}`,
  })
}

export const enter = (node, appears) => {
  const delay = appears ? 0.5 : 1
  let timeline
  timeline = enterSidebarTimeline(node, delay)
  timeline.play()
}

export const exit = (node, sidebarWidth) => {
  const timeline = exitSidebarTimeline(node, sidebarWidth)
  timeline.play()
}

const duration = {
  enter: 1300,
  exit: 800,
}

const LefkasRoutes = ({ windowSize }) => {
  const sidebarWidth = windowSize.width > sizes.phone ? '490px' : '100%'
  return (
    <React.Fragment>
      {routes.map(({ pathname, Component, exact }, index) => (
        <LocalizedRoute
          key={`${pathname}-${index.toString()}`}
          exact={exact}
          path={pathname}
        >
          {({ match }) => {
            return (
              <Transition
                appear
                in={match != null}
                onEntering={(node, appears) => enter(node, appears)}
                onExit={node => exit(node, sidebarWidth)}
                timeout={duration}
                mountOnEnter
                unmountOnExit
              >
                <Component />
              </Transition>
            )
          }}
        </LocalizedRoute>
      ))}
    </React.Fragment>
  )
}

LefkasRoutes.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps, {})(LefkasRoutes)
