import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { colors } from '../../../assets/Styles'
import { LocalizedNavLink } from '../../../modules'

const Wrapper = styled.div`
  position: fixed;
  /* Should be on below of Preview */
  z-index: 100;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  outline: 0;
  width: 100px;
  background: #fff;
  padding: 1rem;
  border: 1px solid;
  box-shadow: 4px 4px 0px 0px ${colors.light5};
  text-align: center;
  &:hover {
    box-shadow: none;
  }
  span {
  }
`

const Button = ({ pathname, message }) => {
  return (
    <LocalizedNavLink to={pathname}>
      <Wrapper>
        <FormattedMessage id={`${message}`}>
          {message => message}
        </FormattedMessage>
      </Wrapper>
    </LocalizedNavLink>
  )
}

Button.propTypes = {
  pathname: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default Button
