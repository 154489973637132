import styled from 'styled-components'
import { colors, device } from '../../../assets/Styles'

export const Wrapper = styled.div`
  z-index: 100;
  position: absolute;
  left: -100%;
  background: ${colors.light1};
  width: 100%;
  @media ${device.tablet} {
    width: 100%;
    overflow-y: scroll;
  }
`

export const Container = styled.div`
  padding: 2rem 4rem 4rem;
  margin: 0 auto;
  max-width: 800px;
  @media ${device.tablet} {
    width: 80%;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 3rem 1rem;
  }
`

export const H1 = styled.h1`
  border: none;
  display: inline-block;
  padding: 0.2rem 0;
  display: inline;
  font-size: 350%;
  text-align: center;
  margin: 0 auto;
  @media ${device.laptop} {
    font-size: 210%;
  }

  @media ${device.tablet} {
    font-size: 180%;
  }
`

export const H2 = styled.h2`
  margin: 1rem auto;
  @media ${device.laptop} {
    font-size: 130%;
  }
  @media ${device.tablet} {
    font-size: 130%;
    text-align: center;
  }
`
export const H3 = styled.h3`
  margin: 2rem auto;
`
export const P = styled.p`
  margin: 0 auto;
  font-size: 100%;
  text-align: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  align-items: flex-start;
`

export const Footer = styled.footer`
  padding: 1rem 2rem 0 2rem;
  margin-top: 6rem;
  width: 100%;
  @media ${device.tablet} {
    margin: 0;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    &:first-child {
      margin-right: 0.5rem;
    }
  }
  @media ${device.tablet} {
    display: block;
  }
`
export const Item = styled.div`
  flex: 0 1 50%;
`

export const Label = styled.div`
  font-size: 90%;
  margin-bottom: 0.6rem;
  color: ${colors.black};
`
export const Span = styled.span`
  color: ${colors.red};
`

export const InputWrapper = styled.div``
export const FieldWrapper = styled.div`
  margin-bottom: 1rem;
`
export const Input = styled.input`
  background: ${colors.light1};
  font-size: 90%;
  font-style: italic;
  appearance: none;
  transition-property: border;
  transition-duration: 0.2s;
  border: 1px solid ${colors.light6};
  border-radius: 2px;
  padding: 15px 10px;
  outline: none;
  appearance: none;
  &:focus,
  &:hover {
    border: 1px solid ${colors.dark2};
  }
  width: 100%;
`
export const TextArea = styled.textarea`
  background: ${colors.light1};
  font-size: 90%;
  font-style: italic;
  transition-property: border;
  transition-duration: 0.2s;
  resize: none;
  appearance: none;
  border: 1px solid ${colors.light6};
  border-radius: 2px;
  padding: 10px 10px;
  outline: none;
  width: 100%;
  &:focus,
  &:hover {
    border: 1px solid ${colors.dark2};
  }
`

export const Button = styled.button`
  font-size: 90%;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #fff;
  background: ${colors.dark1};
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;
  position: relative;
  outline: none;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  vertical-align: middle;
  transition: 0.3s ease-out;
  border: none;
  border-radius: 2px;
  height: 36px;
  float: right;
  line-height: 36px;
  padding: 0 16px;
  &:hover {
    background-color: ${colors.dark4};
  }
  @media ${device.tablet} {
    width: 100%;
    float: none;
  }
`

export const Error = styled.div`
  min-height: 1.2rem;
  margin-top: 0.2rem;
`

export const ErrorSpan = styled.span`
  background: ${colors.yellow};
  border-radius: 3px;
  padding: 0 0.3rem;
  font-size: 90%;
`
export const Form = styled.form``
