import styled, { css } from 'styled-components'

export const sizes = {
  desktop: 1300,
  tablet: 925,
  phone: 780,
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `

  return acc
}, {})

const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

export const device = Object.keys(size).reduce((acc, cur) => {
  acc[cur] = `(max-width: ${size[cur]}px)`
  return acc
}, {})
export default device

// Colors from google material design.
//
export const colors = {
  white: '#fff',
  light0: '#f7f7f7',
  light1: '#EDEDED',
  light2: '#EEEEEE',
  light3: '#DDDDDD',
  light4: '#CCCCCC',
  light5: '#BBBBBB',
  light6: '#AAAAAA',

  dark0: '#222222',
  dark1: '#333333',
  dark2: '#444444',
  dark3: '#555555',
  dark4: '#666666',
  dark5: '#777777',
  dark6: '#999999',

  yellow: '#fffde7',
  red: '#EB6060',

  brown1: '#f0f0e8',

  darkGrey: '#212121',
  grey: '#D4EDF1',
  borderGrey: '#bdbdbd',
  lightBrown: '#e4e4e4',
  backgroundGreen: '#e8f3ec',
  backgroundGrey: '#f4f0ed',
  backgroundBlue: '#F3F9FD',
  green: '#058273',
  background: 'hsl(0, 10%, 89%)',
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 2rem;
`
export const FieldWrapper = styled.div`
  flex-grow: 1;
`

export const Heading2 = styled.h2`
  font-size: 180%;
  margin: 0;
`

export const Label = styled.label`
  display: block;
  margin: 0 0 0.29rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.93em;
  font-weight: 700;
  text-transform: none;
`
export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  flex: 0 1 32%;
  padding: 2em 2.5em;
  text-align: left;
  color: #262b38;
  background: #eef0f3;
  border-top: 3px solid #eef0f3;
  transition: border-color 0.3s;
`
