import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  fetchProjects,
  setHovered,
  setSelected,
} from '../../actions/projectActionCreators'
import { sizes } from '../../assets/Styles'
import { getProjects, getSelectedProject } from '../../reducers'
import SEO from '../SEO'
import LefkasRoutes from './LefkasRoutes'
import Map from './Map/Map'
import Button from './SideBar/Button'

const Styling = styled.div.attrs({
  className: 'lefkas-wrapper',
})`
  opacity: 0;
  visibility: hidden;
`

const Lefkas = React.memo(props => {
  const containerRef = React.useRef(null)
  const [button, setButton] = useState(0)
  const {
    fetchProjects,
    project,
    projects,
    selectedId,
    hoveredId,
    windowSize,
    setSelected,
    locale,
  } = props

  const buttonProps = [
    { message: 'lefkas.button.projects', pathname: 'lefkas/map/projects' },
    { message: 'lefkas.button.map', pathname: 'lefkas/map' },
  ]

  const setProject = id => {
    setSelected(id)
  }

  useEffect(() => {
    fetchProjects(locale)
  }, [locale])

  const projectPath = useRouteMatch({
    path: '/:locale/lefkas/map/projects/:projectId',
    isExact: false,
    strict: false,
  })

  useEffect(() => {
    if (projectPath) {
      setProject(projectPath.params.projectId)
    }
  }, [selectedId])

  const lefkasPath = useRouteMatch({
    path: '/:locale/lefkas',
    isExact: true,
    strict: false,
  })

  const mapPath = useRouteMatch({
    path: '/:locale/lefkas/map',
    isExact: false,
    strict: false,
  })
  const history = useHistory()
  const intl = useIntl()
  useEffect(() => {
    if (lefkasPath.isExact) history.push(`/${intl.locale}/lefkas/map/projects`)
  }, [])

  const projectsPath = useRouteMatch({
    path: '/:locale/lefkas/map/projects',
    isExact: false,
    strict: false,
  })

  useEffect(() => {
    if (mapPath) setButton(0)
    if (projectsPath) setButton(1)
  }, [mapPath, projectsPath])

  const onMouseEnter = id => {
    const { setHovered } = props
    setHovered(id)
  }

  const onMouseLeave = () => {
    const { setHovered } = props
    setHovered(null)
  }

  return (
    <Styling>
      <div ref={containerRef}>
        <SEO title="lefkas.title" description="lefkas.meta.description" />
        {windowSize.width <= sizes.phone && (
          <Button
            path="lefkas/map/projects"
            message={buttonProps[button].message}
            pathname={buttonProps[button].pathname}
          />
        )}
        {containerRef.current && (
          <Map
            width={containerRef.current.offsetWidth}
            project={project}
            windowSize={windowSize}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            projects={projects}
            setProject={setProject}
            hoveredId={hoveredId}
            selectedId={selectedId}
          />
        )}
        <LefkasRoutes />
      </div>
    </Styling>
  )
})

Lefkas.defaultProps = {
  project: null,
  selectedId: null,
  hoveredId: null,
}

Lefkas.propTypes = {
  locale: PropTypes.string.isRequired,
  setHovered: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchProjects: PropTypes.func.isRequired,
  project: PropTypes.objectOf({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  selectedId: PropTypes.string,
  hoveredId: PropTypes.string,
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  projects: getProjects(state),
  project: getSelectedProject(state),
  isFetching: state.projects.isFetching,
  selectedId: state.projects.selectedId,
  hoveredId: state.projects.hoveredId,
  windowSize: state.page.windowSize,
  locale: state.page.locale,
})

export default connect(mapStateToProps, {
  fetchProjects,
  setSelected,
  setHovered,
})(Lefkas)
