import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { colors, device } from '../../assets/Styles'
import { LocalizedNavLink } from '../../modules'

const Styling = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  @media ${device.tablet} {
    
    color: ${colors.dark6};
    padding: 0.8rem 0;
    margin: 1rem auto;
  }

  .item {
    font-size: 85%;
    color: ${colors.dark5};
    transition: color 1s ease-in-out;
    cursor: pointer;
    &.active {
      color: ${colors.dark1};
      background: rgb(255, 253, 231);
    }
    &:hover {
      color: ${colors.light6};
    }
  }
  
  svg {
    width: 0.5rem;
    margin: 0 0.5rem;
    transition: all ease-in-out 0.3;
    fill: ${colors.dark6};
    @media ${device.tablet} {
      color: ${colors.light4};
    }
  }
`

const Breadcrump = React.memo(({ routes }) => {
  const { messages } = useIntl()
  return (
    <Styling>
      {routes.map((route, index) => (
        <div key={index.toString()}>
          <LocalizedNavLink exact className="item" to={route.path}>
            {messages[route.title]}
          </LocalizedNavLink>

          {index + 1 < routes.length && (
            <svg viewBox="0 0 129 129" transform="rotate(180)">
              <path d="m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z" />
            </svg>
          )}
        </div>
      ))}
    </Styling>
  )
})

Breadcrump.propTypes = {
  routes: PropTypes.arrayOf.isRequired,
}

export default Breadcrump
