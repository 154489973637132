import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import { connect } from 'react-redux'
import animations from '../../animations/LandingAnimations'

const duration = 1200

const Slide = ({ children, windowSize, ...props }) => {
  return (
    <Transition
      {...props}
      appear
      timeout={duration}
      mountOnEnter
      unmountOnExit
      onEnter={node => animations.fromTop(node, windowSize)}
      onExit={node => animations.toTop(node)}
    >
      {children}
    </Transition>
  )
}

Slide.propTypes = {
  windowSize: PropTypes.shape({}).isRequired,
}

const mapStateToProps = state => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps, {})(Slide)
