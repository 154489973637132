import {
  SET_WINDOW_WIDTH,
  SET_LOCALE,
  SET_LOADER,
} from '../constants/ActionTypes'

export const setWindowDimensions = (windowSize = {}) => {
  return {
    type: SET_WINDOW_WIDTH,
    windowSize,
  }
}

export const setLocale = locale => {
  return {
    type: SET_LOCALE,
    locale,
  }
}

export const setLoader = boolean => ({
  type: SET_LOADER,
  boolean,
})
