import PropTypes from 'prop-types'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as LogoPromoGr } from '../../assets/images/logo-promo-gr.svg'
import { ReactComponent as LogoPromo } from '../../assets/images/logo-promo.svg'
import { ReactComponent as LogoIcon } from '../../assets/images/logo.svg'
import { colors } from '../../assets/Styles'

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Styling = styled.div.attrs({
  className: 'logo',
})`
  &:hover {
    animation: ${shake} 0.5s ${props => (props.shouldAnimate ? 'infinite' : '')};
    animation-delay: 2s;
    animation: ${shake} 0.5s linear;
  }
  .logo-container {
    cursor: pointer;
    display: flex;
    flex-direction: ${props => props.flexDirection};
    align-items: center;
    .logo-wrapper {
      display: ${props => props.display};
      svg.logo-icon {
        height: ${props => props.logoHeight};

        g {
          fill: ${props => props.fill};
        }
      }
    }

    .logo-promo-wrapper {
      margin-left: -10px;
      padding-top: 7px;
      svg {
        height: ${props => props.promoHeight || '3rem'};
      }
    }
  }
`

const Logo = React.memo(
  ({
    shouldAnimate,
    locale,
    flexDirection,
    logoHeight,
    promoHeight,
    withPromo,
    display,
  }) => {
    return (
      <Styling
        logoHeight={logoHeight}
        promoHeight={promoHeight}
        flexDirection={flexDirection}
        shouldAnimate={shouldAnimate}
        fill={`${colors.black}`}
        display={display}
      >
        <div className="logo-container">
          <div className="logo-wrapper">
            <LogoIcon className="logo-icon" />
          </div>
          {withPromo && (
            <div className="logo-promo-wrapper">
              {locale === 'en' ? <LogoPromo /> : <LogoPromoGr />}
            </div>
          )}
        </div>
      </Styling>
    )
  },
)

Logo.propTypes = {
  locale: PropTypes.string,
  withPromo: PropTypes.bool,
  shouldAnimate: PropTypes.bool,
  logoHeight: PropTypes.string.isRequired,
  flexDirection: PropTypes.string,
  promoHeight: PropTypes.string,
}
Logo.defaultProps = {
  locale: 'en',
  withPromo: false,
  shouldAnimate: false,
  flexDirection: 'center',
  promoHeight: '6rem',
}
export default Logo
