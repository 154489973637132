import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Image,
  Transformation,
  CloudinaryContext,
  Placeholder,
} from 'cloudinary-react'

const Picture = styled.picture`
    width: 100%;
  img {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    transition: filter 1s ease-in-out;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
`

export const BlurImageLoader = (props) => {
  const { name } = props
  return (
    <CloudinaryContext cloudName="dqtacqxog">
      <Image
        {...props}
        responsive
        width="auto"
        crop="scale"
        responsiveUseBreakpoints="true"
        publicId={name}
      >
        <Placeholder type="blur" />
        <Transformation quality="auto" fetchFormat="auto" />
      </Image>
    </CloudinaryContext>
  )
}
