import { gsap } from 'gsap'
import ContactAnimations from './ContactAnimations'
import LandingAnimations from './LandingAnimations'
import MenuAnimations from './MenuAnimations'
import { sizes } from '../assets/Styles'

export const fadeIn = (node, delay) => {
  return gsap.timeline().to(
    node,
    {
      duration: 0.5,
      ease: 'power3.easeInOut',
      autoAlpha: 1,
    },

    `+=${delay}`,
  )
}

export const fadeOut = (node, delay) => {
  return gsap.timeline().to(
    node,
    {
      duration: 0.1,
      ease: 'power3.easeInOut',
      autoAlpha: 0,
    },

    `+=${delay}`,
  )
}

const enterDefaultTimeline = (node, delay) => {
  return fadeIn(node, delay)
}

const exitDefaultTimeline = (node) => {
  return fadeOut(node)
}

export const enter = (node, appears, path, windowSize) => {
  const delay = appears ? 0 : 0.6
  let timeline = gsap.timeline()
  if (path === '') {
    if (windowSize.width > sizes.phone) {
      const delay = appears ? 2 : 0
      timeline = LandingAnimations.enterTimeline(node, delay, windowSize)
    }
  } else if (path === 'contact' || path === 'reserve') {
    timeline = ContactAnimations.enterTimeline(node, delay)
  } else if (path === 'restaurant') {
    timeline = enterDefaultTimeline(node, delay)
  } else if (path === 'index') {
    timeline = MenuAnimations.enterTimeline(node, delay)
  } else {
    timeline = enterDefaultTimeline(node, delay)
  }
  window.loadPromise.then(() => timeline.play())
}

export const exit = (node, path, windowSize) => {
  let timeline = gsap.timeline()

  if (path === '') {
    if (windowSize.width > sizes.phone) {
      timeline = LandingAnimations.exitTimeline(node)
    }
  } else if (path === 'restaurant') {
    timeline = exitDefaultTimeline(node)
  } else if (path === 'contact' || path === 'reserve') {
    timeline = ContactAnimations.exitTimeline(node)
  } else if (path === 'index') {
    timeline = MenuAnimations.exitTimeline(node)
  } else {
    timeline = exitDefaultTimeline(node)
  }

  window.loadPromise.then(() => timeline.play())
}

export const scaleButtonUp = (targets) => {
  const timeline = gsap.timeline()

  return timeline
    .set(targets.backgroundRef.current, {
      zIndex: '20',
    })
    .to(targets.backgroundRef.current, {
      duration: 0.5,
      ease: 'power3.easeInOut',
      scale: 100,
    })

    .to(
      targets.containerRef.current,
      {
        duration: 0.2,
        ease: 'power3.easeInOut',
        opacity: 0,
      },
      '-=0.5',
    )

    .set(targets.backgroundRef.current, {
      zIndex: '1',
      scale: '1',
    })
}
