import { gsap } from 'gsap'

export default {
  enterTimeline(node, delay) {
    const container = node.querySelectorAll('.desktop-container')
    const imageLeft = node.querySelectorAll('.image-left')
    const imageRight = node.querySelectorAll('.image-right')
    const timeline = gsap.timeline()
    return timeline
      .delay(delay)
      .fromTo(
        container,
        { top: '-50%' },
        {
          duration: 0.2,
          ease: 'power2.easeInOut',
          top: '50%',
        },
        'pullDown',
      )

      .fromTo(
        imageLeft,
        { top: '-50%' },
        {
          duration: 0.7,
          ease: 'power2.easeInOut',
          top: '50%',
        },
        'pullDown',
      )
      .fromTo(
        imageRight,
        { top: '-50%' },
        {
          duration: 0.7,
          ease: 'power2.easeInOut',
          top: '50%',
        },

        'pullDown',
      )
      .fromTo(
        node,
        { autoAlpha: 0 },
        {
          duration: 1.5,
          ease: 'power2.easeInOut',
          autoAlpha: 1,
        },
        'pullDown',
      )
  },
  exitTimeline(node) {
    const container = node.querySelector('.desktop-container')
    const imageLeft = node.querySelector('.image-left')
    const imageRight = node.querySelector('.image-right')
    const timeline = gsap.timeline({ paused: true })
    return timeline
      .to(
        container,

        {
          duration: 0.7,
          ease: 'power2.easeInOut',
          top: '-50%',
        },
        'pullUp',
      )

      .to(
        imageLeft,

        {
          duration: 0.5,
          ease: 'power2.easeIn',
          top: '-50%',
        },
        'pullUp',
      )
      .to(
        imageRight,
        {
          duration: 0.5,
          ease: 'power2.easeIn',
          top: '-50%',
        },
        'pullUp',
      )
    // .to(
    //   node,
    //
    //   {
    //     duration: 0.5,
    //     ease: 'power2.easeInOut',
    //     opacity: 0,
    //   },
    //   '-=1',
    // )
  },

  // Welcome slide from top
  fromTop(node, windowSize) {
    const wrapper = node.querySelectorAll('.welcome-wrapper')
    const timeline = gsap.timeline()
    return timeline
      .set(node, {
        top: `-${(windowSize.height * 70) / 100 + 200}px`,
      })
      .to(node, {
        duration: 1.5,
        ease: 'power3.easeInOut',
        top: '0',
      })
      .from(
        node,
        {
          duration: 0.2,
          ease: 'power3.easeInOut',
          autoAlpha: 0,
        },
        '-=0.5',
      )
  },

  toTop(node) {
    // Welcome slide to top
    const timeline = gsap.timeline()
    return timeline.to(node, {
      duration: 1.3,
      ease: 'power3.easeInOut',
      top: `-${node.offsetHeight + 200}px`,
    })
  },
}
