import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { device } from '../../assets/Styles'
import SEO from '../SEO'
import { BlurImageLoader } from '../shared/BlurImageLoader'
import { Content, H1, ImageColumn, P, TextColumn, Wrapper } from './HomeStyles'

const ContentPositioned = styled(Content)`
  position: absolute;
  top: 20%;
  text-align: left;
  @media ${device.tablet} {
    position: relative;
    top: 0;
    text-align: center;
  }
`

const Lathyria = React.memo(() => {
  return (
    <Wrapper>
      <SEO
        title="home.lathyria.title"
        description="home.lathyria.meta.description"
      />

      <TextColumn alignItems="flex-end">
        <ContentPositioned>
          <H1>
            <FormattedMessage id="home.lathyria.title">
              {(message) => message}
            </FormattedMessage>
          </H1>
          <P>
            <FormattedMessage id="home.lathyria.p">
              {(message) => message}
            </FormattedMessage>
          </P>
        </ContentPositioned>
      </TextColumn>
      <ImageColumn alignItems="center">
        <BlurImageLoader name="lathyrous_vegafh" alt="Lathyria from Karya" />
      </ImageColumn>
    </Wrapper>
  )
})

export default Lathyria
