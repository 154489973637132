import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { sizes } from '../../assets/Styles'
import Desktop from './Desktop'
import Mobile from './Mobile'

const areEqual = (prevProps, nextProps) => {
  return prevProps.windowSize.width === nextProps.windowSize.width
}
function Landing(props) {
  const { windowSize, locale } = props
  return (
    <React.Fragment>
      {windowSize.width > sizes.phone ? (
        <Desktop locale={locale} />
      ) : (
        <Mobile />
      )}
    </React.Fragment>
  )
}

Landing.propTypes = {
  locale: PropTypes.string.isRequired,
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
  locale: state.page.locale,
})

export default connect(mapStateToProps)(Landing)
