import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { media, device } from '../../../assets/Styles'

const Styling = styled.div`
  font-size: ${(props) => props.fontSize};
  .address-main {
    text-align: ${(props) => props.textAlign};
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
      padding: 0;
      text-align: center;
    }
    p {
      margin: 0;
      width: 100%;
    }
    a {
      border-bottom: 1px solid;
    }

    header {
      margin-bottom: 2rem;

      h1 {
        box-shadow: none;
        font-size: 150%;
        margin: 0;
        display: inline;
        line-height: 3.5rem;
        @media ${device.tablet} {
          font-size: 150%;
        }
      }
    }
    section {
      margin-bottom: 2rem;
      h3 {
        font-size: 150%;
        margin: 0;
        @media ${device.tablet} {
          font-size: 160%;
        }
      }
    }
  }
`

const Address = React.memo((props) => {
  return (
    <Styling {...props}>
      <div className="address-main">
        <header>
          <h1>
            <FormattedMessage id="address.header">
              {(message) => message}
            </FormattedMessage>
          </h1>
          <p>
            <FormattedMessage id="address.storeOpen.title">
              {(message) => message}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="address.storeOpen.hours">
              {(message) => message}
            </FormattedMessage>
          </p>
        </header>
        <section>
          <h3>
            <FormattedMessage id="address.callUs">
              {(message) => message}
            </FormattedMessage>
          </h3>
          <a href="tel:+302645041769">+302645041769</a>
        </section>
        <section>
          <h3>
            <FormattedMessage id="address.visitUs">
              {(message) => message}
            </FormattedMessage>
          </h3>
          <a
            href="https://goo.gl/maps/ZyHw6CdEsDF2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="address.address">
              {(message) => message}
            </FormattedMessage>
          </a>
        </section>
        <section>
          <h3>
            <FormattedMessage id="address.emailUs">
              {(message) => message}
            </FormattedMessage>
          </h3>
          <a href="mailto:hello@pardalo.gr">hello@pardalo.gr</a>
        </section>
      </div>
    </Styling>
  )
})

Address.defaultProps = {
  textAlign: 'left',
  fontSize: '',
}

Address.propTypes = {
  textAlign: PropTypes.string,
  fontSize: PropTypes.string,
}

export default Address
