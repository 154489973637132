import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'

export function LocalizedNavLink(props) {
  const { locale } = useIntl()
  const { to, isinactive } = props
  return isinactive ? (
    <NavLink {...props} to={`#`} />
  ) : (
    <NavLink {...props} to={`/${locale}/${to}`} />
  )
}

LocalizedNavLink.propTypes = {
  isinactive: PropTypes.bool,
  to: PropTypes.string.isRequired,
}
