import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Fade from '../../shared/Fade'
import SEO from '../../SEO'
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react'
import { client } from '../../../prismic'
import { MenuStyles } from './MenuStyles'
import { toPrismicLocale } from './Prismic'
import { useIntl } from 'react-intl'

function ThirdPage(props) {
  const [data, setData] = useState(null)
  const { locale } = useIntl()
  const getData = async () => {
    try {
      const response = await client.getByUID('menu_category', 'main', {
        lang: toPrismicLocale(locale),
      })
      setData(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getData()
  })

  useEffect(() => {}, [data])

  return (
    <Fade {...props}>
      <MenuStyles>
        <div classname="container">
          <SEO
            title="menu.navigation.main"
            description="menu.main.meta.description"
          />
          <div className="category-container">
            <section className="category-section">
              {data &&
                data.menu_items.map((item) => (
                  <div className="item">
                    <span>
                      <h5 classname="title" inline>
                        {item.title}
                      </h5>
                      <div className="description">
                        <span>{item.description}</span>
                        <div className="price">{item.price}</div>
                      </div>
                    </span>
                  </div>
                ))}
            </section>
          </div>

          <div className="category-footer">
            <FormattedMessage id="menu.footer.message1">
              {(message) => message}
            </FormattedMessage>
          </div>
        </div>
      </MenuStyles>
    </Fade>
  )
}

export default ThirdPage
